
import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment';
import queryString from 'query-string';

import { toast } from 'react-toastify';

import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as AdjustmentTimecardDucks from 'ducks/timecards/adjustmentTimecard';
import * as AdjustmentTypesDucks from 'ducks/vendors/adjustmentTypes';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as SessionDucks from 'ducks/activities/session';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as ProfileDucks from 'ducks/accounts/user';
import * as UserDucks from 'ducks/accounts/user';
import * as TimecardDucks from 'ducks/timecards/timecard';
import cookie from "react-cookies";


import AdjustmentHourFormModalComponent from 'components/timecards/dailyTimecardList/AdjustmentHourFormModalComponent';
import AddTimeOffTimecardModal from 'components/timecards/dailyTimecardList/AddTimeOffTimecardModal';
import TimecardDeleteModalComponent from 'components/timecards/dailyTimecardList/TimecardDeleteModal';
import PtoTimecardDeleteModalComponent from 'components/timecards/dailyTimecardList/PtoTimecardDeleteModal';

import SectionLoaderAtom from 'atoms/SectionLoader';
import DailyTimecardTableComponent from 'components/timecards/customDailyTimecardList/Table';
import SessionMapModalComponent from 'components/activities/activityTrackList/SessionMapModal';
import { MSGS } from 'constants/timecards';
import { server } from 'helpers/config';
import OverrideMealModal from 'components/timecards/dailyTimecardList/OverrideMealConfirmationModal';
import FeedbackModal from 'components/timecards/dailyTimecardList/FeedbackModal';
import TimecardWarning from 'sections/timecards/customList/TimecardWarning';


import StorageGateway from "lib/storage-gateway";
import DoNotShowModal from 'components/common/DoNotShowModal';
import PtoWarningModal from 'components/common/PtoWarningModal';


const GeocodingOption = server.geocodingOption;

/**
 * DailyTimecardListPage -> DailyTimecardListSection
 *
 * Components:
 *    - {@link DailyTimecardTableComponent}
 *
 * State:
 *    - daily timecard list
 *
 * Actions:
 *    None
 */
 let oAuthToken = "";
 let company_id = "";
class DailyTimecardListSection extends React.Component {
  static isSessionValidated(detail) {
    // const { PunchIn, PunchOut} = this.state;

    if (moment(detail.start_datetime) > moment(detail.end_datetime)) {
      toast.error('Shift can not be created as End datetime must be greater than start datetime');
      return false;
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.getLocationAddress = this.getLocationAddress.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.trackingToggle = this.trackingToggle.bind(this);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.resetModalData = this.resetModalData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.update = this.update.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.getActivityLogs = this.getActivityLogs.bind(this);
    this.approveDailyTimecard = this.approveDailyTimecard.bind(this);
    this.saveData = this.saveData.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.updateBoxes = this.updateBoxes.bind(this);
    this.goTo = this.goTo.bind(this);
    this.addMeal = this.addMeal.bind(this);
    this.addShift = this.addShift.bind(this);
    this.addNewShift = this.addNewShift.bind(this);
    this.updateNewShift = this.updateNewShift.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.selectedShift = this.selectedShift.bind(this);
    this.fetchNextLogs = this.fetchNextLogs.bind(this);
    this.isValidated = this.isValidated.bind(this);
    this.disapproveDailyTimecard= this.disapproveDailyTimecard.bind(this);
    this.payMeal = this.payMeal.bind(this);
    this.deletedt = this.deletedt.bind(this);
    this.toggleMealModal = this.toggleMealModal.bind(this);
    this.openPayMealModal = this.openPayMealModal.bind(this);
    this.changeMealStatus = this.changeMealStatus.bind(this);
    this.deleteModalToggle = this.deleteModalToggle.bind(this);
    this.closePayMealModal = this.closePayMealModal.bind(this);
    this.updateMileage = this.updateMileage.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
    this.feedbackModal = this.feedbackModal.bind(this);
    this.showPreviousOrNext = this.showPreviousOrNext.bind(this);
    this.showFeedbackInComment = this.showFeedbackInComment.bind(this);
    this.reverseParseDate = this.reverseParseDate.bind(this);
    this.browserParseDate = this.browserParseDate.bind(this);
    this.togglePtoModal= this.togglePtoModal.bind(this);
    this.deletePtoTimecard=this.deletePtoTimecard.bind(this)
    this.toggleDeletePtoTimecardModal=this.toggleDeletePtoTimecardModal.bind(this)
    this.savePtoTimecardData=this.savePtoTimecardData.bind(this)
    this.closeDoNotShowModal = this.closeDoNotShowModal.bind(this);
    this.handleDoNotShowMsg = this.handleDoNotShowMsg.bind(this);
    this.handleDoNotShowMsgPto = this.handleDoNotShowMsgPto.bind(this);

    this.toggleWarningModal = this.toggleWarningModal.bind(this);
    this.updateState = this.updateState.bind(this);
    this.submitManualSession = this.submitManualSession.bind(this);
    this.closePtoWarningModal = this.closePtoWarningModal.bind(this);


    this.state = {
      isTrackingModalLoading: false,
      isLocationLoading: false,
      permission: true,
      isModalOpen: false,
      idSelected:null,
      dateSelected:null,
      adjustmentHourView:false,
      isActivityOpen:false,
      idTrackingSelected: null,
      isValidityBoxLoading: false,
      isShiftLoading:false,
      isInfoModalOpen: true,
      isInfoLoading: false,
      openMissingMeal: false,
      openManualCorrection: false,
      openMileageDeviation: false,
      openPunchOut:false,
      openForceOut:false,
      openComment:false,
      selectedId:null,
      selectedShiftData:null,
      PunchIn: null,
      PunchOut: null,
      mealIn: null,
      mealOut: null,
      errorIds:[],
      type: null,
      loadedShift:null,
      items:{},
      pageSize:20,
      showMore:true,
      isCommentLoading:false,
      openMealModal: false,
      mealOverrideItem : null,
      confirmMealStatusLoader: false,
      rejectMealStatusLoader: false,
      confirmdeleteLoader: false,
      isFeedbackModalOpen: false,
      questionNumber : 0,
      DTDate : null,
      isFeedbackLoading: false,
      openTimefenceBreach: false,
      isSurveyLoading: false,
      isFeedbackCmntLoader: false,
      showMessage: false,
      showFeedback: false,
      openPtoModal : false,
      selectedDate :null,
      isDeletePtoTimecardModalOpen:false,
      deletePtoTimecardItem:null,
      ptoMode : null,
      ptoPolicyId : null,
      isApproving: false,
      openWarningModal: false , isConfirm: false,ptoWarningModal:false,
      ptoWarningMessage:"",
      doNotShowModal: {
        isOpen: false,
        message: "",
        userId: null,
      },
      user_Id: null,
      futureData:{
        detail:null,
        query:null,
        bool: null,
        urlParams: null,
        params:null,
        data:null,
      },
      futureDataEdit:{
        detail:null,
        seconds:null,
        edit:false,
        query:null,
        childItem:null,
        shiftData:null,
        itemType:null,
        data:null
      }
    };
  }

/**
 * Closes the "Do Not Show" modal and resets its state.
 */
  closeDoNotShowModal(){
    this.setState({
      doNotShowModal: {
        isOpen: false,
        message: "",
        userId: null,
      }
    })
  }

  closePtoWarningModal(){
    this.setState({ptoWarningModal:false});
  }

/**
 * Handles the "Do Not Show" message submission.
 * @param {boolean} check - Indicates whether to check the option.
 */
  async handleDoNotShowMsg(check){
    try {
      const { doNotShowModal } = this.state;
      await this.props.UserDucks.putDontShowPTOWarning({...doNotShowModal, check: check});
    } catch (error) {
      toast.error("Something went wrong!");
    }
    this.closeDoNotShowModal();
  }

  async handleDoNotShowMsgPto(check){
    try {
      await this.props.UserDucks.putDontShowPTOWarningModal({userId: this.state.user_Id, check: check});
    } catch (error) {
      toast.error("Something went wrong!");
    }
    this.closePtoWarningModal();
  }

/**
 * Updates a specific state property.
 * @param {string} key - The state property to update.
 * @param {*} value - The value to set for the state property.
 */
  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

/**
 * Toggles the warning modal based on the current state and cookies.
 */
  toggleWarningModal() {
    const { openWarningModal, isConfirm, futureData, futureDataEdit } = this.state;
    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : futureDataEdit?.query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : futureDataEdit?.query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));

    this.setState({openWarningModal:false})
    this.setState({isShiftLoading: true});
    if(futureDataEdit.edit===true){
      this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...futureDataEdit.query,
        department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
         id: futureDataEdit?.query?.employee_id, paginate: false, admin: true, beta: 'true' })
      .then(() => {
        this.setState({ 
            isConfirm: false,
            PunchIn : null, PunchOut : null, mealIn : null, mealOut: null,
            isShiftLoading: false,
            futureDataEdit: {
              detail:null,
              seconds:null,
              edit:false,
              query:null,
              childItem:null,
              shiftData:null,
              data:null
            } 
        });
    })
    .catch(error => {
        console.log(error);
    });
   
    } 
    else{
    this.props.DailyTimecardDucks.getCustomDailyTimecards({...futureData.query,
      department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
         id: futureData?.query?.employee_id, paginate: false, admin: true, beta: 'true' })
    .then(() => {
        this.setState({ 
            isConfirm: false,
            PunchIn : null, PunchOut : null, mealIn : null, mealOut: null,
            isShiftLoading: false,
            futureData: {
                detail: null,
                query: null,
                bool: null,
                urlParams: null,
                params: null,
            } 
        });
    })
    .catch(error => {
        console.log(error);
    });
  }

  }

/**
 * Submits a manual session for the shift.
 */
  submitManualSession(){
    this.setState({openWarningModal:false,isConfirm:false})
    this.setState({isShiftLoading: true}); 
    const {futureData,errorIds,futureDataEdit} = this.state;
    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : futureDataEdit?.query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : futureDataEdit?.query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));

if(futureDataEdit.edit === true){
this.setState({futureDataEdit:{edit:false}})
  let itemType = futureDataEdit?.data;

  this.props.ActivitySplitDucks.putActivitySplit({...futureDataEdit.detail, beta: 'true', seconds: futureDataEdit.seconds })
  .then(()=>{
    this.setState({ isShiftLoading: true});
    this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...futureDataEdit.query,
      department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
         id: futureDataEdit?.query?.employee_id, paginate: false, admin: true, beta: 'true' })
      .then(()=>{
        const splitData = futureDataEdit?.childItem && futureDataEdit?.childItem.activity_split && futureDataEdit?.childItem.activity_split.map((item) => {
          if(item.activity_code === 'PUNCH-IN'){
            return item
          }
          else
            return false
        }
        );
        let errorone=[];
        if(futureDataEdit?.data.activity_code === "MEAL-PERIOD"){
          errorone = errorIds.filter(item => item !== futureDataEdit?.shiftData.id);
          itemType = futureDataEdit?.shiftData;
        }
        else {
          errorone = errorIds.filter(item => item !== futureDataEdit?.data.id);
          itemType = futureDataEdit?.data;
        }
        if((itemType.task_split_exists && itemType.task_split_exists > 1) || this.props.showAllocateTasksPopup){
          this.props.showTasksModal(itemType ,futureDataEdit?.childItem);
        }
        toast.success("Shift updated successfully");
        this.setState({ isShiftLoading: false, PunchIn : null, PunchOut : null, mealIn: null,mealOut: null, errorIds: errorone});
      })
  })
  .catch((err)=>{
    if(futureDataEdit?.data.activity_code === "MEAL-PERIOD"){
      errorIds.push(futureDataEdit?.shiftData.id);
      itemType = futureDataEdit?.shiftData;
    }
    else {
      errorIds.push(futureDataEdit?.data.id);
      itemType = futureDataEdit?.data;
    }
    if((itemType.task_split_exists && itemType.task_split_exists > 1) || this.props.showAllocateTasksPopup){
      this.props.showTasksModal(itemType,futureDataEdit?.childItem);
    }
    this.props.updateErrorState('inline');   
    this.setState({ isShiftLoading: false, PunchIn : null, PunchOut : null,  mealIn: null,mealOut: null, errorIds});
    if(err.split_in_session && err.split_in_session[0]==="You cannot edit the shift as there are valid activities within this punch in session")
    {
      toast.error("Please adjust any conflicting activities to continue");
    }
    else{
      toast.error(JSON.stringify(err.split_in_session && err.split_in_session[0]));
      toast.error(JSON.stringify(err.non_field_errors && err.non_field_errors[0]));
    }
  })
}


else{
    this.props.SessionDucks.postSession(futureData.detail, futureData.query?.employee_id , futureData.bool, futureData.urlParams)
        .then((res)=>{
         
          toast.success("Shift added successfully");
          if(res.value?.show_warning){
            this.setState({doNotShowModal: {
              isOpen: true,
              message: res.value?.warning,
              userId: res.value?.user_id
            }})
          }
          this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...futureData.query,
            department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
         id: futureData?.params?.id, paginate: false, admin: true, beta: 'true'})
            .then(()=>{
              const error = errorIds.filter(item => item !== futureData?.data.id);
              this.setState({ PunchIn : null, PunchOut : null, mealIn : null, mealOut: null, errorIds: error, isShiftLoading: false});
            })
        })
        .catch((err)=>{
          errorIds.push(futureData?.data.id)
          this.props.DailyTimecardDucks.resetShiftTime({ ...futureData?.data, beta: 'true'});
          // this.props.DailyTimecardDucks.resetMealTime(data);
          this.props.updateErrorState('inline');
          this.setState({PunchIn : null, PunchOut : null, mealIn : null, mealOut: null, errorIds,  isShiftLoading: false});
          toast.error(JSON.stringify(err.split_in_session && err.split_in_session[0]));
          toast.error(JSON.stringify(err.non_field_errors && err.non_field_errors[0]));
        })
      }
  }



/**
 * Parses a date string using the user's timezone and converts it to the browser's timezone.
 * @param {string} dateString - The date string to be parsed.
 * @returns {Object} - A moment object representing the date in the browser's timezone.
 */
  browserParseDate(dateString) {
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment.tz(dateString, userTimezone);
    const newMoment = moment.tz([
    momentWithBrowserTimezone.year(),
    momentWithBrowserTimezone.month(),
    momentWithBrowserTimezone.date(),
    momentWithBrowserTimezone.hour(),
    momentWithBrowserTimezone.minute(),
    momentWithBrowserTimezone.second(),
    momentWithBrowserTimezone.millisecond(),
    ], moment.tz.guess());
    return newMoment;
    }

/**
 * Converts a date string from the browser's timezone to the user's timezone.
 * @param {string} dateString - The date string to be converted.
 * @returns {Object} - A moment object representing the date in the user's timezone.
 */
  reverseParseDate(dateString){
    // convert browser timezone date to user timezone date
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment(dateString);
    const newMoment = moment.tz([
      momentWithBrowserTimezone.year(),
      momentWithBrowserTimezone.month(),
      momentWithBrowserTimezone.date(),
      momentWithBrowserTimezone.hour(),
      momentWithBrowserTimezone.minute(),
      momentWithBrowserTimezone.second(),
      momentWithBrowserTimezone.millisecond(),
    ], userTimezone);
    return newMoment;
  }

/**
 * Retrieves the location address for a given marker.
 * If the address is not present, it fetches the address from the activity track.
 * @param {Object} marker - The marker object containing location information.
 * @returns {Promise|null} - A promise if the address needs to be fetched; otherwise, null.
 */
  getLocationAddress(marker) {
    if(!marker.address){
      return this.props.ActivityTrackDucks.getActivityLocationAddress(marker, GeocodingOption)
        .then(() => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...marker,
            address: selectedMarkerAddress,
          });
        })
    }
    return null;
  }

/**
 * Navigates to the previous or next question in a survey.
 * @param {string} type - The type of navigation ('previous' or 'next').
 */
  showPreviousOrNext(type){
    let count = this.state.questionNumber;
    this.setState({isSurveyLoading: true});
    setTimeout(()=>{
      if(type == 'previous' && count - 1 >= 0){
        this.setState({ questionNumber : count - 1})
        this.forceUpdate(); // Without this line the elements do not re-render
        this.setState({isSurveyLoading: false});
      }
      else if(type == 'next' && ((this.props.feedbackResponseList.length - 1) >= (count + 1))){
        this.setState({ questionNumber : count + 1})
        this.forceUpdate(); // Without this line the elements do not re-render
        this.setState({isSurveyLoading: false});
      }
    },2000);
    
  }

/**
 * Toggles the visibility of the meal modal.
 */
  closePayMealModal(){
    const { openMealModal} = this.state;
    this.setState({openMealModal: !openMealModal})
  }

/**
 * Toggles the delete modal.
 */
  deleteModalToggle() {
    this.setState({
      isDeleteModalOpen: !(this.state.isDeleteModalOpen)
    })
  }

/**
 * Retrieves the address for a specific marker.
 * @param {Object} data - The marker data for which to get the address.
 * @returns {Promise} - A promise that resolves with the address data.
 */
  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

/**
 * Changes the meal status for a given item and child item.
 * @param {Object} item - The item for which the meal status is being changed.
 * @param {Object} childItem - The child item related to the main item.
 */
  changeMealStatus(item, childItem){
    const { location: { query }   } = this.props;
    const { router: {  route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));




    const id = item.id;
    let is_paid;
    let include_rt_ot_dot;
    if(item.is_meal_paid){
      is_paid = false;
      include_rt_ot_dot= false
    }
    else{
      is_paid= true;
      include_rt_ot_dot= true
    }
    this.props.ActivitySplitDucks.payMeal({ id , beta: 'true', is_paid, include_rt_ot_dot})
    .then(()=>{
      this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
        department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
         id: params.id, paginate: false, admin : true, beta: 'true' })
    })
  }

/**
 * Deletes a specified item.
 * @param {Object} item - The item to be deleted.
 */
  deletedt(item){ 
    this.setState({isValidityBoxLoading :false, openMissingMeal: false, openPunchOut: false, openForceOut: false, openComment: false, openManualCorrection: false, loadedShift: null, isCommentLoading: false, openMileageDeviation : false, openTimefenceBreach: false})
    this.setState({isDeleteModalOpen : true});
    this.setState({deleteItem: item});
  }
  /*Delete Pto timecard */ 
  deletePtoTimecard(item)
  {
    this.setState({isDeletePtoTimecardModalOpen : true}); 
    this.setState({deletePtoTimecardItem: item});
  }


/**
 * Toggles the PTO delete modal visibility.
 */
  toggleDeletePtoTimecardModal()
  {
    const {isDeletePtoTimecardModalOpen}=this.state
    this.setState({isDeletePtoTimecardModalOpen : !isDeletePtoTimecardModalOpen});
  }

/**
 * Toggles the PTO modal for editing or creating a new request.
 * @param {Object} data - The data for the PTO request.
 * @param {string} mode - The mode for the modal ('Edit' or 'Create').
 * @param {Object} childItem - The child item related to the PTO request.
 */
  togglePtoModal(data,mode, childItem){
    const { openPtoModal }=this.state;
    if(mode === 'Edit')
    {
      this.setState({ptoPolicyId : data?.request_id})
    }else
    {
      this.setState({ptoPolicyId : null})
    }
    this.setState({ openPtoModal: !openPtoModal,ptoMode:mode})
    if(data)
    {
        this.setState({selectedDate:data?.date ? data?.date : ""})
    }
  }


/**
 * Saves the PTO timecard data.
 * @param {Object} data - The data to be saved.
 * @param {Object} item - The item related to the PTO request.
 * @param {string|null} status - The status of the PTO request (optional).
 * @returns {Promise} - A promise that resolves when the save operation is complete.
 */
  savePtoTimecardData(data,item,status=null){
    const {  location: { query }  } =this.props;
    const { router: {  route: { match: { params },  } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));





    const detail = { ...item}
    
    this.setState({confirmdeleteLoader: true});
    if(status){
      return this.props.ActivitySplitDucks.updatePaidTimeOffRequest({id:data,status})
      .then(()=>{
        this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...params, ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),

          paginate: false, admin: true, beta: 'true' })
          .then(()=>{
            this.props.showAllocateTasksPopup && this.props.showTasksModal();
            toast.success("Shift updated successfully");
            this.setState({isValidityBoxLoading :false, openMissingMeal: false, openPunchOut: false, openForceOut: false, openComment: false, openManualCorrection: false, loadedShift: null, isCommentLoading: false, isDeleteModalOpen: false, confirmdeleteLoader: false, openMileageDeviation: false, openTimefenceBreach: false})
          });
      })
      .catch((error)=>{
        this.setState({isValidityBoxLoading :false, loadedShift: null, isDeleteModalOpen: false, confirmdeleteLoader: false, isCommentLoading: false});
        error && error.reason && toast.error(error.reason[0]);
        error && error.split_in_session && toast.error(error.split_in_session && error.split_in_session[0])
      })
    }
    else
    {
    return this.props.ActivitySplitDucks.deletePaidTimeOffRequest({id:data})
      .then(()=>{
        this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...params, ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),

          paginate: false, admin: true, beta: 'true' })
          .then(()=>{
            this.props.showAllocateTasksPopup && this.props.showTasksModal();
            toast.success("Shift updated successfully");
            this.setState({isValidityBoxLoading :false, openMissingMeal: false, openPunchOut: false, openForceOut: false, openComment: false, openManualCorrection: false, loadedShift: null, isCommentLoading: false, isDeleteModalOpen: false, confirmdeleteLoader: false, openMileageDeviation: false, openTimefenceBreach: false})
          });
      })
      .catch((error)=>{
        this.setState({isValidityBoxLoading :false, loadedShift: null, isDeleteModalOpen: false, confirmdeleteLoader: false, isCommentLoading: false});
        error && error.reason && toast.error(error.reason[0]);
        error && error.split_in_session && toast.error(error.split_in_session && error.split_in_session[0])
      })
    }
  }
  /*......End......*/
/**
 * Handles the payment of a meal by updating the meal status and fetching updated timecard data.
 * 
 * @method payMeal
 * @returns {void}
 */
  payMeal(){
    this.setState({confirmMealStatusLoader : true});
    const { location: { query }   } = this.props;
    const { router: { route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));



    const { mealOverrideItem } = this.state;
    const id = mealOverrideItem.id;
    let is_paid;
    let include_rt_ot_dot;
    if(mealOverrideItem.is_meal_paid){
      is_paid = false;
      include_rt_ot_dot= false
    }
    else{
      is_paid= true;
      include_rt_ot_dot= true
    }

    this.props.ActivitySplitDucks.payMeal({ id , beta: 'true', is_paid, include_rt_ot_dot})
    .then(()=>{
      toast.success('Meal added towards RT, OT and DOT successfully');
      this.setState({openMealModal : false, confirmMealStatusLoader : false});
      this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
        department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),

        id: params.id, paginate: false, admin : true, beta: 'true' })
    })
    .catch(()=>{
      this.setState({openMealModal : false, confirmMealStatusLoader : false});
      this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
        department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin : true, beta: 'true' })
    })
  }

  /**
  * Toggles the visibility of the payment modal and sets the meal item to be paid.
  * 
  * @method openPayMealModal
  * @param {Object} child - The child item to be processed.
  * @param {Object} childItem - Additional item data (not used in the method).
  * @returns {void}
  */
  openPayMealModal(child, childItem){
    const { openMealModal } = this.state;
    this.setState({openMealModal : !openMealModal});
    this.setState({mealOverrideItem :  child});     
  }

  
  /**
  * Handles the rejection of a meal payment and updates the timecard data.
  * 
  * @method toggleMealModal
  * @returns {void}
  */
  toggleMealModal(){
    this.setState({rejectMealStatusLoader: true});
    const { location: { query }   } = this.props;
    const { router: { route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    const { mealOverrideItem } = this.state;
    const id = mealOverrideItem.id;
    let is_paid;
    let include_rt_ot_dot;
    if(mealOverrideItem.is_meal_paid){
      is_paid = false;
      include_rt_ot_dot= false
    }
    else{
      is_paid= true;
      include_rt_ot_dot = false
    }
    this.props.ActivitySplitDucks.payMeal({ id , beta: 'true', is_paid, include_rt_ot_dot})
    .then(()=>{
      const { openMealModal } = this.state;
      this.setState({openMealModal : !openMealModal, rejectMealStatusLoader : false});
      this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
        department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin : true, beta: 'true' })
    })
  }


  /**
   * Fetches activity logs based on the provided id and data.
   * Toggles the loading state and opens the info modal.
   * @param {number} id - The ID of the entity to fetch logs for.
   * @param {object} data - The data object containing additional info.
   */
  getActivityLogs(id, data){
    const { isInfoLoading } = this.state;
    let selectedId = null;
    let selectedShiftData = null;
    if (data) {
      selectedId = data.id;
      selectedShiftData = data;
    }
    this.setState({isInfoLoading: !isInfoLoading, selectedId, selectedShiftData, isInfoModalOpen: true});
    if(!isInfoLoading) {
      this.props.ActivitySplitDucks.getActivityLogs({ entity_id : id, page_size: 20, beta: 'true', entity_type: 'activitysession' })
        .then(()=>{
          this.setState({isInfoModalOpen : false, showMore:true});
        })
        .catch((err)=>{
          this.setState({isInfoModalOpen : false});
        })
    }
  }

  /**
  * Validates the given activity list against specified conditions.
  * @param {Array} activityList - The list of activities to validate.
  * @param {object} data - Additional data for validation.
  * @returns {boolean} - Returns true if valid, false otherwise.
  */
  isValidated(activityList, data) {
    if(activityList[1]){
      if (moment(activityList[1].start_datetime) < moment(activityList[0].start_datetime) ||
        moment(activityList[1].start_datetime) > moment(activityList[0].end_datetime) ||
        moment(activityList[1].end_datetime) < moment(activityList[0].start_datetime) ||
        moment(activityList[1].end_datetime) > moment(activityList[0].end_datetime)) {
        if(activityList[1].activity_code === 'MEAL-PERIOD'){
          toast.error('Meal-Period must be between Punch-In session');
          this.props.DailyTimecardDucks.resetShiftTime({...data, beta: 'true'});
          this.setState({ PunchIn : null, PunchOut : null, mealIn : null, mealOut: null});
        }
        return false;
      }
    }
    return true;
  }



  
  /**
  * Fetches the next set of logs for the specified ID.
  * @param {number} id - The ID of the entity to fetch logs for.
  */
  fetchNextLogs(id){
    const { pageSize} = this.state;

    setTimeout(() => this.props.ActivitySplitDucks.getActivityLogs({ entity_id : id, page_size: pageSize +10, beta: 'true', entity_type: 'activitysession' })
      .then(()=>{
        const { activityLogs } = this.props;
        const itemsNext = activityLogs && activityLogs.results && activityLogs.results.length && activityLogs.results.map(item =>item);
        if( itemsNext.length === activityLogs.count){
          this.setState({showMore:false});
        }
        else this.setState({items : itemsNext, pageSize : pageSize + 10 });
      })
      .catch((error)=>console.log('error', error))
      , 500);
  }

  /**
  * Updates the state of validity items.
  * @param {string} key - The state key to update.
  * @param {any} value - The value to set for the state key.
  * @param {number} id - The ID of the selected item.
  * @param {object} item - The item associated with the update.
  */
  updateBoxes(key, value, id, item) {
    this.setState({ [key]: value ,  selectedId : id});
    if(item){
      this.showFeedbackInComment(item);
    }
  }

  /**
  * Shows feedback in the comment section based on the provided item.
  * @param {object} item - The item to show feedback for.
  */
  showFeedbackInComment(item){
    const { isFeedbackCmntLoader } = this.state;
    const context_ids = {"session_id": item.session_id }
    this.setState({isFeedbackCmntLoader: true});
    this.props.DailyTimecardDucks.feedbackTemplate({context_ids : context_ids, non_others:true, latest: true})
    .then((res)=>{
      var message = item.message && item.message[0];
      const feedback = res.value?.results;
      if(message && feedback && feedback.length){
        const msgDate = new Date(message.timestamp);
        const feedDate = new Date(feedback[0].create_date);

        if(msgDate > feedDate){
          /*showMessage=true;
          showFeedback=false;*/
          this.setState({showMessage:true, showFeedback:false});
          this.setState({isFeedbackCmntLoader: false});
        }
        else if(msgDate < feedDate){
          /*showMessage=false;
          showFeedback=true;*/
          this.setState({showMessage:false, showFeedback:true});
          this.setState({isFeedbackCmntLoader: false});
        }
      }
      else if(!message && feedback && feedback.length){
        /*showMessage=false;
        showFeedback=true;*/
        this.setState({showMessage:false, showFeedback:true});
        this.setState({isFeedbackCmntLoader: false});
      }
      else if(message && feedback && feedback.length == 0){
        /*showMessage=true;
        showFeedback=false;*/
        this.setState({showMessage:true, showFeedback:false});
        this.setState({isFeedbackCmntLoader: false});
      }
      else if(!message && feedback && feedback.length == 0){
        this.setState({showMessage:false, showFeedback:false});
        this.setState({isFeedbackCmntLoader: false});
      }
      else{
        this.setState({showMessage:false, showFeedback:false});
        this.setState({isFeedbackCmntLoader: false}); 
      }
    })
    .catch(()=>{
      this.setState({isFeedbackCmntLoader: false});
    })
  }

  /**
  * Navigates to the specified URL with the provided data and child item.
  * @param {string} url - The URL to navigate to.
  * @param {object} data - The data to include in the navigation.
  * @param {object} childItem - Additional item data for navigation.
  */
  goTo(url, data, childItem) {
    const { location: { query }   } = this.props;
    const { router: { history, route: { match: { params } } } } = this.context;
    history.push({
      pathname: url,
      search: queryString.stringify({
        employee: params.id,
        parent: JSON.stringify(query),
        split_id:data.id,
        is_approved:childItem.is_approved,
        first_name: query.first_name,
        last_name: query.last_name,
      }),
    });
  }


  /**
  * Saves the provided data and updates the status or reason.
  * @param {object} data - The data to save.
  * @param {object} item - The item associated with the save action.
  * @param {number} id - The ID of the item to update.
  * @returns {Promise} - A promise that resolves on successful save.
  */
  saveData(data, item, id){
    const {  location: { query }  } =this.props;
    const { router: {  route: { match: { params },  } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));



    const detail = { ...item}

    if (id) {
      detail.reason = data;
      detail.status = item.status;
      this.setState({isCommentLoading :true, loadedShift : id, confirmdeleteLoader: true});
    }
    else if(data.value && !id){
      detail.status = data.value;
      this.setState({isValidityBoxLoading :true, loadedShift : id, confirmdeleteLoader: true});
    }
    return this.props.ActivitySplitDucks.putActivitySplit({...detail, beta: 'true'})
      .then(()=>{
        this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...params, ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 paginate: false, admin: true, beta: 'true' })
          .then(()=>{
            this.props.showAllocateTasksPopup && this.props.showTasksModal();
            toast.success("Shift updated successfully");
            this.setState({isValidityBoxLoading :false, openMissingMeal: false, openPunchOut: false, openForceOut: false, openComment: false, openManualCorrection: false, loadedShift: null, isCommentLoading: false, isDeleteModalOpen: false, confirmdeleteLoader: false, openMileageDeviation: false, openTimefenceBreach: false})
          });
      })
      .catch((error)=>{
        this.setState({isValidityBoxLoading :false, loadedShift: null, isDeleteModalOpen: false, confirmdeleteLoader: false, isCommentLoading: false});
        error && error.reason && toast.error(error.reason[0]);
        error && error.split_in_session && toast.error(error.split_in_session && error.split_in_session[0])
      })
  }


  /**
   * Toggles the modal for viewing or editing adjustment hours.
   * @param {Object} data - The data associated with the adjustment.
   * @param {string} mode - The mode of the modal ('Edit', 'View', etc.).
   * @param {Object} childItem - The child item related to the adjustment.
   * @returns {boolean} - Returns true if the modal state changes.
   */
  toggleModal(data,mode, childItem) {
    const { isModalOpen }=this.state;
    if(mode === 'Edit'){
      this.setState({ idSelected:data.id });
    }
    if(!isModalOpen){
      // if(moment(data.date) >  moment(moment().format('YYYY-MM-DD'))){
      //   return toast.error('You cannot add adjustments for a future date');
      // }
      return this.props.AdjustmentTimecardDucks.getAdjustmentTypesTimecards({ daily_timecard_id: childItem.id, paginate: false, beta: 'true' })
        .then(() => {
          this.setState({ isModalOpen: !isModalOpen,  dateSelected:childItem.date });
          if( mode === 'View'){
            this.setState({ adjustmentHourView: true });
          }
          else{
            this.setState({adjustmentHourView:false});
          }
        });
    }
    else{
      this.setState({ idSelected: null });
    }
    this.setState({isModalOpen: !isModalOpen});
    return true;
  }
  
  /**
   * Updates the adjustment hours based on the provided data.
   * @param {Object} data - The data to update.
   * @returns {Promise} - A promise that resolves when the update is complete.
   */
  update(data){
    const { isModalOpen, idSelected } = this.state;
    const {  location: { query } } = this.props;
    const { router: {  route: { match: { params },  } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));



    let detail = {
      date:moment(data.date).format('YYYY-MM-DD'),
      employee_id:(+query.employee_id),
      adjustments:data.adjustmentHour,
    }
    if(!idSelected){

      if(query.department_ids){
        detail.department_ids = (+query.department_ids);
      }
      if(query.team_id){
        detail.team_id = (+query.team_id);
      }
      return this.props.AdjustmentTimecardDucks.putAdjustmentTypesTimecards(detail)
        .then(() => {
          this.setState({ isModalOpen:!isModalOpen});
          toast.success("Adjustment Hours successfully added");
          this.props.DailyTimecardDucks.getDailyTimecardAdjustments({ employee_id: query.employee_id, start_date: query.start_date, end_date: query.end_date });
          this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
            department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin: true, beta: 'true' });
        })
        .catch((err) => {
          toast.error(err.daily_timecard_id);
          toast.error(err.daily_timecard);
          toast.error(err.total_hours);
          toast.error(err.adjustment_type);
          if(err.non_field_errors && err.non_field_errors.length){
            toast.error(err.non_field_errors[0]);
          }
        });
    }
    return this.props.AdjustmentTimecardDucks.putAdjustmentTypesTimecards({...detail,id:idSelected, beta: 'true'})
      .then(() => {
        this.setState({ isModalOpen:!isModalOpen});
        toast.success("Adjustment Hours successfully updated");
        this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin: true, beta: 'true' })
      })
      .catch((err) => {
        toast.error(err.daily_timecard_id);
        toast.error(err.daily_timecard);
        toast.error(err.total_hours);
        toast.error(err.adjustment_type);
        if(err.non_field_errors.length){
          toast.error(err.non_field_errors[0]);
        }
      });
  }

  /**
   * Approves a daily timecard.
   * @param {Object} data - The data for approval.
   * @returns {Promise} - A promise that resolves when the approval is complete.
   */
  approveDailyTimecard(data) {
    const { location: { query } } = this.props;
    const { router: { route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));



    this.setState({isApproving: true});
    return this.props.DailyTimecardDucks.approveDailyTimecard({...data, beta: 'true'})
      .then(async () => {
        toast.success(MSGS.DAILY_TIMECARD_APPROVAL_SUCCESS);
        await this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin : true, beta: 'true' })
        this.setState({isApproving: false});
      });
  }

  /**
   * Disapproves a daily timecard.
   * @param {Object} data - The data for disapproval.
   * @returns {Promise} - A promise that resolves when the disapproval is complete.
   */
  disapproveDailyTimecard(data) {
    const { location: { query } } = this.props;
    const { router: { route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    this.setState({isApproving: true});
    return this.props.DailyTimecardDucks.disapproveDailyTimecard({...data, beta: 'true'})
      .then(async () => {
        toast.success(MSGS.DAILY_TIMECARD_DISAPPROVAL_SUCCESS);
        await this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin : true, beta: 'true' })
        this.setState({isApproving: false});
      });
  }

  /**
   * Resets the modal data.
   * @returns {Promise} - A promise that resolves when the reset is complete.
   */
  resetModalData(){
    return this.props.ActivityTrackDucks.resetSessionInfo({ beta: 'true'});
  }

  // to create an empty shift row with empty meal
  addShift(data){
    this.props.DailyTimecardDucks.addShift({...data, beta: 'true'});
  }

  // to create an empty meal row
  addMeal(data){
    this.props.DailyTimecardDucks.addMeal({...data, beta: 'true'});
  }

  // sets punchin mealIn mealout punchout times
  addNewShift(data){
    if(data.start_time && data.start_time !== "" && data.new_shift){
      this.setState({ PunchIn: data.start_time});
    }
    if(data.end_time && data.end_time !== "" && data.new_shift){
      this.setState({ PunchOut: data.end_time});
    }
    if(data.start_time && data.start_time !== "" && data.new_meal){
      this.setState({ mealIn: data.start_time});
    }
    if(data.end_time && data.end_time !== "" && data.new_meal){
      this.setState({ mealOut: data.end_time});
    }
  }

  selectedShift(item, type, mealData){
    this.setState({type});
  }

  // function to update time on editable cell
  updateTime(val, data){
    const {type} = this.state;
    const { basicSettingDetail } = this.props;
    const Time = moment(val, "h:mm:ss A").format("HH:mm:ss");

    const splitTime = val.split("");
    const shortFormat = splitTime.find((item)=> item==='p' || item === 'P' || item ==='a' || item==='A');

    let t;
    let StandardTime = moment(val, "h:mm:ss").format("LT");
    if(shortFormat){
      t = moment(val, "h:mm:ss A").format("HH:mm:ss");
      StandardTime = moment(t, "h:mm:ss").format("LT");
    }

    if(type === 'start'){
      if(basicSettingDetail.time_format_24_hrs){
        this.props.DailyTimecardDucks.addShiftTime({...data,start_time :Time, beta: 'true'});
      }
      else {
        this.props.DailyTimecardDucks.addShiftTime({...data,start_time :StandardTime, beta: 'true'});
      }
    }
    else if (type === 'end'){
      if(basicSettingDetail.time_format_24_hrs){
        this.props.DailyTimecardDucks.addShiftTime({...data,end_time :Time, beta: 'true'});
      }
      else this.props.DailyTimecardDucks.addShiftTime({...data,end_time :StandardTime, beta: 'true'});
    }
    else if(type === 'meal-start'){
      if(basicSettingDetail.time_format_24_hrs){
        this.props.DailyTimecardDucks.addMealTime({...data,start_time :Time, beta: 'true'});
      }
      else this.props.DailyTimecardDucks.addMealTime({...data,start_time :StandardTime, beta: 'true'});
    }
    else if (type === 'meal-end'){
      if(basicSettingDetail.time_format_24_hrs){
        this.props.DailyTimecardDucks.addMealTime({...data,end_time :Time, beta: 'true'});
      }
      else this.props.DailyTimecardDucks.addMealTime({...data,end_time :StandardTime, beta: 'true'});
    }
  }

  /**
 * Updates a new shift with specified timings and meal periods.
 *
 * @param {Object} data - The shift data to update.
 * @param {string} startTiming - The start time of the shift in "h:mm:ss A" format.
 * @param {string} endTiming - The end time of the shift in "h:mm:ss A" format.
 * @param {Object} childItem - The child item associated with the shift.
 * @param {string} mealInTiming - The meal in time in "h:mm:ss A" format.
 * @param {string} mealOutTiming - The meal out time in "h:mm:ss A" format.
 * @param {boolean} meal - Flag indicating if a meal is included.
 * 
 * @returns {void}
 *
 * @throws {Error} Throws an error if the meal or shift update fails.
 *
 * @description This function handles the logic for updating shifts and meal times, 
 * taking into account various conditions related to department settings, 
 * ensuring that the shift timings do not overlap with existing activities, 
 * and managing the state for successful and failed operations.
 */
  updateNewShift(data, startTiming, endTiming, childItem, mealInTiming, mealOutTiming, meal) {
    const { location: { query } , departmentAll, defaultPayrollSetting } = this.props;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    const deptSelected = departmentAll.find((i) => i.id === ((query && query.emp_dept_id)));
    const { errorIds,isConfirm } = this.state;
    const { router: { route: { match: { params } } } } = this.context;

    const startTime = moment(startTiming, "h:mm:ss A").format("HH:mm:ss");
    const startDate= data.start_date;
    
    const endTime = moment(endTiming, "h:mm:ss A").format("HH:mm:ss");
    const endDate = data.end_date;
    let  endDateTime = moment(`${endDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss');

    let startDateTime = moment(`${startDate}T${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    if(!data.shift_exist){
      if((deptSelected && !deptSelected.payroll_setting_daily_split) || (!deptSelected && !defaultPayrollSetting.daily_split)){
        if(endTime < startTime){
          const updatedEndDate = moment(data.end_date, "YYYY-MM-DD").add(1,'days').format('YYYY-MM-DD');
          endDateTime = moment(`${updatedEndDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss');
        }
      }
    }
   
    let mealStart;
    let mealEnd;
    let mealStartDate;
    let mealEndDate;
    let mealEndDateTime;
    let mealStartDateTime;

    if(meal){
      mealStart = moment(mealInTiming, "h:mm:ss A").format("HH:mm:ss");
      mealStartDate= data.start_date;

      mealEnd = moment(mealOutTiming, "h:mm:ss A").format("HH:mm:ss");
      mealEndDate = data.end_date;

      mealStartDateTime = moment(`${mealStartDate}T${mealStart}`, 'YYYY-MM-DD HH:mm:ss');
      mealEndDateTime = moment(`${mealEndDate}T${mealEnd}`, 'YYYY-MM-DD HH:mm:ss');
      if(!data.shift_exist){
        if((deptSelected && !deptSelected.payroll_setting_daily_split) || (!deptSelected && !defaultPayrollSetting.daily_split)){
          if(mealStart < startTime){
            const updatedMealStart = moment(mealStartDate, "YYYY-MM-DD").add(1,'days').format('YYYY-MM-DD');
            mealStartDateTime = moment(`${updatedMealStart}T${mealStart}`, 'YYYY-MM-DD HH:mm:ss');
            const updatedMealEnd = moment(mealEndDate, "YYYY-MM-DD").add(1,'days').format('YYYY-MM-DD');
            mealEndDateTime = moment(`${updatedMealEnd}T${mealEnd}`, 'YYYY-MM-DD HH:mm:ss');
          }
          if(mealEnd < mealStart){
            const updatedMealEnd = moment(mealEndDate, "YYYY-MM-DD").add(1,'days').format('YYYY-MM-DD');
            mealEndDateTime = moment(`${updatedMealEnd}T${mealEnd}`, 'YYYY-MM-DD HH:mm:ss');
          }
        }
      }
    }

    if(data.shift_exist && data.new_meal){
      startDateTime = moment(`${startDate}T${startTime}`, 'YYYY-MM-DD HH:mm:ss');
      endDateTime = moment(`${endDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss');

      if(childItem.activity_split[0].start_time > startTime){
        startDateTime = moment(`${endDate}T${startTime}`, 'YYYY-MM-DD HH:mm:ss');
        endDateTime =  moment(`${endDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss');
      }

      if(childItem.activity_split[0].end_time < endTime){
        startDateTime = moment(`${startDate}T${startTime}`, 'YYYY-MM-DD HH:mm:ss');
        endDateTime =   moment(`${startDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss');
      }
    }

    let dnewdata;
    let shiftData;

    childItem && childItem.activity_split.filter((item)=>{
      dnewdata = item.meals && item.meals.filter((mealItem)=> {
        if(mealItem.id === data.id){
          shiftData = item;
        }
        return dnewdata;
      })
      return dnewdata
    })

    let detail;
    const currentDateTime = new Date();
      let startdateTime = this.reverseParseDate(startDateTime).toISOString();
      let enddateTime = this.reverseParseDate(endDateTime).toISOString();
      const isStartFuture = new Date(startdateTime) > currentDateTime;
      const isEndFuture = new Date(enddateTime) > currentDateTime;
    const date = new Date(endDateTime);
    let seconds= false;
    let mealDate = new Date(mealEndDateTime);
    if(!data.shift_exist && mealInTiming && mealOutTiming){
      detail = [{
        start_datetime : startdateTime,
        end_datetime :  enddateTime,
        activity_code : data.activity_code,
        is_future_dt : (isStartFuture || isEndFuture) ? true :undefined,
      },
      {
        start_datetime : this.reverseParseDate(mealStartDateTime).toISOString(),
        end_datetime : this.reverseParseDate(mealEndDateTime).toISOString(),
        activity_code : "MEAL-PERIOD",
        is_future_dt : (isStartFuture || isEndFuture) ? true :undefined,
      }];
      if(date.getSeconds() || mealDate.getSeconds()) {
        seconds = true;
      }
    }
    else{
      detail = [{
        start_datetime : startdateTime,
        end_datetime :  enddateTime,
        activity_code : data.activity_code,
        is_future_dt : (data.activity_code!=="MEAL-PERIOD")&&(isStartFuture || isEndFuture) ? true :undefined,
      }];
      if(date.getSeconds()){
        seconds = true;
      }
    }

    const urlParams = {
      // department_id: query.department_id ? (+query.department_id) : '',
      // team_id: query.team_id ? (+query.team_id) : '',
      payroll_id: query.payroll_id && (query.payroll_id !== 'custom') ? (+query.payroll_id) : '',
      seconds: seconds,
    };
    
    let activityData;
    if(data.new_meal){
      activityData = {
        data :detail,
        split_id : shiftData.id
      }
      this.setState({ loadedShift : shiftData.id});
    }
    else if(data.new_shift) {
      this.setState({ loadedShift : data.id});
    }

    if(data.new_meal){
      this.setState({ isShiftLoading : true});
      this.props.ActivitySplitDucks.postaddMeal({...activityData, beta: 'true', seconds: seconds }, shiftData.id)
        .then(()=>{
          this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
            department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin: true, beta: 'true' })
            .then(()=>{
              const errorMeal = errorIds.filter(item => item !== shiftData.id);
              toast.success("Meal added successfully");
              this.setState({ PunchIn : null, PunchOut : null, mealIn : null, mealOut: null,  isShiftLoading: false, errorIds: errorMeal, loadedShift: shiftData.id});
            })
        })
        .catch((err)=>{
          if((shiftData.task_split_exists && shiftData.task_split_exists  > 1) || this.props.showAllocateTasksPopup){
            this.props.showTasksModal(shiftData,childItem);
          }
          errorIds.push(shiftData.id)
          this.props.DailyTimecardDucks.resetMealTime({...data, beta: 'true'});
          this.props.updateErrorState('inline');
          this.setState({ PunchIn : null, PunchOut : null, mealIn : null, mealOut: null, errorIds,  isShiftLoading: false, loadedShift: shiftData.id});
          toast.error(JSON.stringify(err.split_in_session && err.split_in_session[0]));
          toast.error(JSON.stringify(err.non_field_errors && err.non_field_errors[0]));
        })
    }
   
    if (this.isValidated(detail, data) && !data.new_meal && data.new_shift) {
      this.setState({ isShiftLoading: true });
  
      let futureDateTimeDetected = false;
      if ((((data.activity_code !== "MEAL-PERIOD") && (isStartFuture || isEndFuture)) || (isStartFuture || isEndFuture)) && isConfirm === false) {
          futureDateTimeDetected = true;
          this.setState({ isShiftLoading: false });
      }
  
      if (futureDateTimeDetected) {
          this.setState({
              openWarningModal: true,
              futureData: {
                  detail: detail,
                  query: query,
                  bool: 'true',
                  urlParams: urlParams,
                  params: params,
                  data: data,
              }
          }, () => {
              console.log();
          });
          return false; // Exit function
      }
  
      this.props.SessionDucks.postSession(detail, query.employee_id, 'true', urlParams)
        .then((res)=>{
          toast.success("Shift added successfully");
          if(res.value?.show_warning){
            this.setState({doNotShowModal: {
              isOpen: true,
              message: res.value?.warning,
              userId: res.value?.user_id
            }})
          }
          this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
            department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin: true, beta: 'true'})
            .then(()=>{
              const error = errorIds.filter(item => item !== data.id);
              this.setState({ PunchIn : null, PunchOut : null, mealIn : null, mealOut: null, errorIds: error, isShiftLoading: false});
            })
        })
        .catch((err)=>{
          errorIds.push(data.id)
          this.props.DailyTimecardDucks.resetShiftTime({ ...data, beta: 'true'});
          // this.props.DailyTimecardDucks.resetMealTime(data);
          this.props.updateErrorState('inline');
          this.setState({ PunchIn : null, PunchOut : null, mealIn : null, mealOut: null, errorIds,  isShiftLoading: false});
          toast.error(JSON.stringify(err.split_in_session && err.split_in_session[0]));
          toast.error(JSON.stringify(err.non_field_errors && err.non_field_errors[0]));
        })
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.isLoading == true){
      this.setState({openComment : false, openMissingMeal: false , openManualCorrection : false, openPunchOut : false , openForceOut : false , openManualCorrection : false, openMileageDeviation: false, openTimefenceBreach: false})
    }
  }

  componentWillMount() {
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
  
  }


  /**
   * Handles the click event on a marker.
   * If the marker has an address, it toggles the session info window.
   * If not, and if showBeacon is true, it retrieves the location address.
   *
   * @param {Object} marker - The marker object that was clicked.
   * @param {boolean} showBeacon - Flag indicating whether to show the beacon information.
   */
  handleMarkerClick(marker, showBeacon) {
    // If location address already exists then just toggle
    if (marker.address) return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
    if(showBeacon) return this.getLocationAddress(marker);
  }

  /**
   * Toggles the tracking modal and fetches job activity track data.
   * @param {Object} data - The data object containing job details.
   */
  trackingToggle(data) {
    const { isTrackingOpen } = this.state;
    this.setState({ isTrackingOpen: !isTrackingOpen });
    if (data && data.id) {
      this.setState({ isTrackingModalLoading: true, isLocationLoading: true });
      this.props.ActivityTrackDucks.getJobActivityTrack({ ...data, beta: 'true'})
        .then(() => {
          this.setState({ permission: true });
          const { sessionInfo } = this.props;
          const arr = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code !== 'BEACON');
          this.codeLatLng(arr, sessionInfo && sessionInfo.punch_in_type);
          this.props.GeoFenceDucks.getActivityTrackGeofences({...data, beta: 'true'})
            .then(() => {
              this.setState({ isTrackingModalLoading: false })
            })
            .catch(() => this.setState({ isTrackingModalLoading: false, isLocationLoading: false }));
        })
        .catch((err) => {
          this.setState({ permission: false, isLocationLoading: false });
        })
    }
  }

  /**
   * Processes latitude and longitude to fetch activity location addresses.
   * @param {Array} array - An array of location objects.
   * @param {string} type - The type of punch-in.
   */
  codeLatLng(array, type) {
    const updatedArr = array.length ? array.filter((i)=> i.position && i.position.lat !== undefined && i.position.lng !== undefined) : array;
    if(!updatedArr || !updatedArr.length) return this.setState({ isLocationLoading: false });
    this.setState({ isLocationLoading:  true });
    Promise.all(updatedArr.map((item, index) =>
      setTimeout(()=>{this.props.ActivityTrackDucks.getActivityLocationAddress(item, GeocodingOption)
        .then((res) => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...item,
            address: selectedMarkerAddress,
          });
           if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })
          }
        })
        .catch(() => {
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })            
          }
        })
      }, 100*index)
    ))
      .catch(() => this.setState({ isLocationLoading:  false }));
    return null;
  }

  /**
   * Toggles the information window for a given marker.
   * @param {Object} marker - The marker to toggle.
   */
  toggleInfoWindow(marker) {
    return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
  }

  /**
   * Toggles the collapse state of the daily timecard.
   * @param {Object} data - The data object for the timecard.
   */
  toggleCollapse(data){
    this.props.DailyTimecardDucks.toggleCollapse(data);
  }

  /**
   * Updates the table data based on provided parameters.
   * @param {Object} data - The data object for the update.
   * @param {Object} childItem - The child item associated with the data.
   * @param {string} type - The type of update (e.g., 'custom').
   * @param {string} key - The key indicating which field is being updated.
   * @returns {boolean} - Returns true if the update was successful.
   */
  updateTable(data, childItem, type, key) {
    const { errorIds } = this.state;
    const { location: { query } , departmentAll, defaultPayrollSetting } = this.props;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    const deptSelected = departmentAll.find((i) => i.id === ((query && query.emp_dept_id)));

    let shiftData;
    let dnewdata;
    let itemType = data;


    // filter the shift for which meal is edited
    childItem && childItem.activity_split.filter((item)=>{
      dnewdata = item.meals && item.meals.filter((mealItem)=> {
        if(mealItem.id === data.id){
          shiftData = item;
        }
        return dnewdata;
      })
      return dnewdata
    })
    const startTime = moment(data.start_time, "h:mm:ss A").format("HH:mm:ss");
    const startDate= moment(data.start_datetime).format("YYYY-MM-DD");

    const endTime =moment(data.end_time, "h:mm:ss A").format("HH:mm:ss");
    const endDate= moment(data.end_datetime).format("YYYY-MM-DD");

    let startDateTime = moment(`${startDate}T${startTime}`, 'YYYY-MM-DD HH:mm:ss');
    let endDateTime = moment(`${endDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss');

    const currentDateTime = new Date();
      let startdateTime = this.reverseParseDate(startDateTime).toISOString();
      let enddateTime = this.reverseParseDate(endDateTime).toISOString();
      const isStartFuture = new Date(startdateTime) > currentDateTime;
      const isEndFuture = new Date(enddateTime) > currentDateTime;


    const shiftStartDate = data.activity_code === 'MEAL-PERIOD' ? moment(shiftData.start_datetime).format('YYYY-MM-DD') : moment(data.start_datetime).format('YYYY-MM-DD');

    if((deptSelected && !deptSelected.payroll_setting_daily_split) || (!deptSelected && !defaultPayrollSetting.daily_split)){
      if(data.activity_code === 'MEAL-PERIOD'){
        if(moment(data.start_time, "h:mm:ss A") < moment(shiftData.start_time, "h:mm:ss A")){
          startDateTime = moment(`${moment(shiftStartDate).add(1, 'days').format('YYYY-MM-DD')}T${startTime}`, 'YYYY-MM-DD HH:mm:ss')
        }
        else{
          startDateTime = moment(`${shiftStartDate}T${startTime}`, 'YYYY-MM-DD HH:mm:ss')
        }
        if(moment(data.end_time, "h:mm:ss A") < moment(shiftData.start_time, "h:mm:ss A")){
          endDateTime = moment(`${moment(shiftStartDate).add(1, 'days').format('YYYY-MM-DD')}T${endTime}`, 'YYYY-MM-DD HH:mm:ss')
        }
        else{
          endDateTime = moment(`${shiftStartDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss')
        }
      }
      else {
        const shift = childItem.activity_split.find((i) => i.id === data.id);
        // when end time has changed
        if(shift.end_time !== data.end_time){
          if(moment(data.end_time, "h:mm:ss A") < moment(shift.start_time, "h:mm:ss A")){
            endDateTime = moment(`${moment(shiftStartDate).add(1, 'days').format('YYYY-MM-DD')}T${endTime}`, 'YYYY-MM-DD HH:mm:ss')
          }
          else {
            endDateTime = moment(`${shiftStartDate}T${endTime}`, 'YYYY-MM-DD HH:mm:ss')
          }
        }
        else{
          endDateTime = shift.end_time;
        }
      }
    }
    
    if(key === 'start_time'){
      endDateTime = this.browserParseDate(data.end_datetime_tz).toISOString();
    }
    let detail;
    if(type === 'custom'){
      detail = {...data}
    }
    else {
      detail = { ...data,
        start_datetime : this.reverseParseDate(startDateTime).toISOString(),
        end_datetime :  this.reverseParseDate(endDateTime).toISOString(),
      }
    }

    let seconds = false;
    const newEndDate = new Date(endDateTime);

    if(newEndDate.getSeconds()){
      seconds = true;
    }

    if(data.activity_code === "MEAL-PERIOD") this.setState({ loadedShift : shiftData.id});
    else this.setState({ loadedShift : data.id});
    this.setState({ isShiftLoading: true });
    // const errorRow = errorIds && errorIds.find((errorItem)=> (errorItem === data.id));
    let errorRow;
    let editData = false;
    if(data.activity_code === 'MEAL-PERIOD'){
      errorRow = errorIds && errorIds.find((errorItem)=> (errorItem === shiftData.id));
      if(errorRow === shiftData.id){
        editData = true;
      }
    }
    else{
      errorRow = errorIds && errorIds.find((errorItem)=> (errorItem === data.id));
      if(errorRow === data.id){
        editData = true;
      }
    }

    // if(moment(detail.start_datetime) > moment() || moment(detail.end_datetime) > moment()){
    //   this.setState({ isShiftLoading: false, PunchIn : null, PunchOut : null,  mealIn: null,mealOut: null, errorIds});
    //   this.props.updateErrorState('inline');
    //   return toast.error('Time entered should be less than current time')
    // }

    if(data.start_datetime !== detail.start_datetime || data.end_datetime !== detail.end_datetime || type === 'custom' || editData){
          

      let futureDateTimeDetected = false;
      if ((((data.activity_code !== "MEAL-PERIOD") && (isStartFuture || isEndFuture)) && (isStartFuture || isEndFuture))) {
          futureDateTimeDetected = true;
          this.setState({ isShiftLoading: false });
      }
  
      if (futureDateTimeDetected) {
          this.setState({
              openWarningModal: true,
              futureDataEdit: {
                detail: detail,
                seconds: seconds,
                edit:true,
                query:query,
                childItem:childItem,
                shiftData:shiftData,
                data:data,
                
            }
          }, () => {
              console.log();
          });
          return false; // Exit function
      }




      return this.props.ActivitySplitDucks.putActivitySplit({...detail, beta: 'true', seconds: seconds })
        .then(()=>{
          this.setState({ isShiftLoading: true});
          this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
            department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: query.employee_id, paginate: false, admin: true, beta: 'true' })
            .then(()=>{
              const splitData = childItem && childItem && childItem.activity_split && childItem.activity_split.map((item) => {
                if(item.activity_code === 'PUNCH-IN'){
                  return item
                }
                else
                  return false
              }
              );
              let errorone=[];
              if(data.activity_code === "MEAL-PERIOD"){
                errorone = errorIds.filter(item => item !== shiftData.id);
                itemType = shiftData;
              }
              else {
                errorone = errorIds.filter(item => item !== data.id);
                itemType = data;
              }
              if((itemType.task_split_exists && itemType.task_split_exists > 1) || this.props.showAllocateTasksPopup){
                this.props.showTasksModal(itemType ,childItem);
              }
              toast.success("Shift updated successfully");
              this.setState({ isShiftLoading: false, PunchIn : null, PunchOut : null, mealIn: null,mealOut: null, errorIds: errorone});
            })
        })
        .catch((err)=>{
          if(data.activity_code === "MEAL-PERIOD"){
            errorIds.push(shiftData.id);
            itemType = shiftData;
          }
          else {
            errorIds.push(data.id);
            itemType = data;
          }
          if((itemType.task_split_exists && itemType.task_split_exists > 1) || this.props.showAllocateTasksPopup){
            this.props.showTasksModal(itemType,childItem);
          }
          this.props.updateErrorState('inline');   
          this.setState({ isShiftLoading: false, PunchIn : null, PunchOut : null,  mealIn: null,mealOut: null, errorIds});
          if(err.split_in_session && err.split_in_session[0]==="You cannot edit the shift as there are valid activities within this punch in session")
          {
            toast.error("Please adjust any conflicting activities to continue");
          }
          else{
            toast.error(JSON.stringify(err.split_in_session && err.split_in_session[0]));
            toast.error(JSON.stringify(err.non_field_errors && err.non_field_errors[0]));
          }
        })
    }
    this.setState({isShiftLoading: false});
    return true;
  }


   /**
   * Updates the mileage for a specific child item and data.
   * @param {Object} data - The data object containing mileage details.
   * @param {Object} childitem - The child item object associated with the mileage update.
   */
  updateMileage(data, childitem) {
    this.setState({ isShiftLoading: true});
    this.setState({ loadedShift : data.id});
    const { location: { query } } = this.props;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    const { router: { route: { match: { params } } } } = this.context;
    
    this.props.DailyTimecardDucks.updateMileage({...childitem, data,  beta: 'true'})
      .then(() => {        
        toast.success('Mileage successfully updated');
        this.props.DailyTimecardDucks.getCustomDailyTimecards({ ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
 id: params.id, paginate: false, admin : true, beta: 'true' })
        .then(()=>{
              this.setState({ isShiftLoading: false})
          })
      })
      .catch((err)=>{
          this.setState({ isShiftLoading: false});
          toast.error(JSON.stringify(err.split_in_session && err.split_in_session[0]));
          toast.error(JSON.stringify(err.non_field_errors && err.non_field_errors[0]));
        })
  }

  /**
   * Toggles the feedback modal for a specific session item.
   * @param {Object} item - The session item to provide feedback on.
   * @param {Object} childitem - The child item associated with the session.
   */
  feedbackModal(item, childitem){
    const { isFeedbackModalOpen } =this.state;
    const context_ids = {"session_id": item.session_id }
    this.setState({isFeedbackLoading : true})
    if(!isFeedbackModalOpen){
      this.props.DailyTimecardDucks.feedbackTemplate({context_ids : context_ids, others:true})
      .then(()=>
        this.setState({isFeedbackLoading : false})
        )
      .catch(()=>
        this.setState({isFeedbackLoading : false})
        )
      this.setState({DTDate : childitem.date_display})
    }
    else{
      this.setState({DTDate : null, questionNumber: 0})
    }
    this.setState({isFeedbackModalOpen: !isFeedbackModalOpen});
  }


  render() {
    const {
      isLoading,
      dailyTimecardList,
      location,
      basicSettingDetail,
      sessionInfo,
      selectedMarkerAddress,
      geoFenceDetail,
      payPeriodTotal,
      adjustmentTimecardList,
      adjustmentTypesList,
      dateFormat,
      timeFormat,
      timeFormat24Hrs,
      activityLogs,
      departmentAll,
      showableAttributes,
      isError,
      userTimecardFilters,
      title,
      fetchData,
      assignedAdjustmentsList,
      assignedPaidTimeOffList
    } = this.props;
    const { isTrackingOpen, isModalOpen, mealIn, mealOut, showMore, isTrackingModalLoading, isLocationLoading, permission, isShiftLoading, isValidityBoxLoading, isInfoLoading,
      dateSelected, adjustmentHourView, idSelected, idTrackingSelected, isInfoModalOpen, type, loadedShift, items, selectedShiftData,
      openMissingMeal, errorIds, openForceOut, openPunchOut, openComment, openManualCorrection, selectedId, PunchIn, PunchOut, isCommentLoading , openMealModal, isDeleteModalOpen, openMileageDeviation,openTimefenceBreach,openPtoModal ,isDeletePtoTimecardModalOpen , ptoMode , ptoPolicyId,
      isApproving, doNotShowModal, openWarningModal,ptoWarningModal,
      ptoWarningMessage} =this.state;

    let date;
    if(Object.keys(basicSettingDetail).length){
      date = moment(dateSelected).format(basicSettingDetail.date_format_display);
    }

    let adjustmentsType = adjustmentTypesList && adjustmentTypesList.length ? adjustmentTypesList : [];

    const adjustmentTimecard = adjustmentTimecardList.length ? adjustmentTimecardList : [];
    let newAdjustmentTimecard = adjustmentTimecardList;
    let newAdjustmentTypeList = adjustmentsType;
    if(adjustmentTypesList.length !== adjustmentTimecard.length){
      const adjustmentIds = adjustmentTimecard.map((i) => i.adjustment_type_id);
      const remainingList = adjustmentTypesList.length ? adjustmentTypesList.filter((i) => adjustmentIds.indexOf(i.id) < 0) : null;
      const newRemainingList = remainingList && remainingList.map((i, index) => ({ ...i,
        adjustment_type: null,
        adjustment_type_id: i.id,
        daily_timecard :null,
        daily_timecard_id : null,
        double_over_time_hours : 0,
        double_over_time_mins : 0,
        id : null,
        over_time_hours : 0,
        over_time_mins : 0,
        regular_time_mins : 0,
        regular_time_hours : 0,
      }));
      newAdjustmentTimecard = adjustmentTimecard.concat(newRemainingList);
      newAdjustmentTypeList = adjustmentTypesList.length ? adjustmentTypesList.concat(newRemainingList) : [];
    }
    const { router: { route: { match: { params } } } } = this.context;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        {isDeleteModalOpen ?
          <TimecardDeleteModalComponent
            isOpen={isDeleteModalOpen}
            toggle={this.deleteModalToggle}
            data={this.state.deleteItem}
            saveData={this.saveData}
            confirmdeleteLoader={this.state.confirmdeleteLoader}
          />
        : null}
         {isDeletePtoTimecardModalOpen &&
          <PtoTimecardDeleteModalComponent
            isOpen={isDeletePtoTimecardModalOpen}
            toggle={this.toggleDeletePtoTimecardModal}
            saveData={this.savePtoTimecardData}
            data={this.state.deletePtoTimecardItem}
            confirmdeleteLoader={this.state.confirmdeleteLoader}
          />
        }
        <DailyTimecardTableComponent
          departmentAll ={departmentAll}
          data={dailyTimecardList}
          location={location}
          basicSettingDetail={basicSettingDetail}
          trackingToggle={this.trackingToggle}
          payPeriodTotal={payPeriodTotal}
          approveDailyTimecard={this.approveDailyTimecard}
          disapproveDailyTimecard={this.disapproveDailyTimecard}
          toggleModal={this.toggleModal}
          idSelected={idSelected}
          idTrackingSelected={idTrackingSelected}
          saveData={this.saveData}
          goTo={this.goTo}
          toggleCollapse={this.toggleCollapse}
          updateTable={this.updateTable}
          isValidityBoxLoading={isValidityBoxLoading}
          isShiftLoading={isShiftLoading}
          getActivityLogs={this.getActivityLogs}
          activityLogs={activityLogs}
          isInfoModalOpen={isInfoModalOpen}
          isInfoLoading={isInfoLoading}
          updateBoxes={this.updateBoxes}
          openManualCorrectionState={openManualCorrection}
          openMileageDeviationState={openMileageDeviation}
          openMissingMealState={openMissingMeal}
          openForceOutState={openForceOut}
          openPunchOutState={openPunchOut}
          openCommentState={openComment}
          selectedId={selectedId}
          selectedShiftData={selectedShiftData}
          addShift={this.addShift}
          addMeal={this.addMeal}
          addNewShift={this.addNewShift}
          updateNewShift={this.updateNewShift}
          PunchIn={PunchIn}
          PunchOut={PunchOut}
          mealIn={mealIn}
          mealOut={mealOut}
          dateFormat = {dateFormat}
          timeFormat24Hrs = {timeFormat24Hrs}
          timeFormat={timeFormat}
          errorId={errorIds}
          updateTime={this.updateTime}
          selectedShift={this.selectedShift}
          type={type}
          loadedShift={loadedShift}
          fetchNextData={this.fetchNextLogs}
          items={items}
          showMore={showMore}
          isCommentLoading={isCommentLoading}
          openPayMealModal={this.openPayMealModal}
          changeMealStatus = {this.changeMealStatus}
          defaultPayrollSetting={this.props.defaultPayrollSetting}
          deletedt = {this.deletedt}
          showableAttributes={showableAttributes}
          userExtraData={this.props.userExtraData}
          updateMileage={this.updateMileage}
          showTasksModal = {this.props.showTasksModal}
          showAllocateTasksPopup={this.props.showAllocateTasksPopup}
          editDisabled = {this.props.editDisabled}
          isLoading = {isLoading}
          isError = {isError}
          isFeedbackModalOpen={this.state.isFeedbackModalOpen}
          feedbackModal={this.feedbackModal}
          openTimefenceBreachState={openTimefenceBreach}
          dateFormatDisplay={this.props.dateFormatDisplay}
          showEmployeeListing = {this.props.showEmployeeListing}
          isShowEmployee = {this.props.isShowEmployee}
          showEmployeeCookie={this.props.showEmployeeCookie}
          feedbackResponseList={this.props.feedbackResponseList}
          isFeedbackCmntLoader={this.state.isFeedbackCmntLoader}
          showMessage={this.state.showMessage}
          showFeedback={this.state.showFeedback}
          userTimecardFilters={userTimecardFilters}
          userTimezone={this.props.userTimezone}
          togglePtoModal={this.togglePtoModal}
          deletePtoTimecard={this.deletePtoTimecard}
          savePtoTimecardData = {this.savePtoTimecardData}
          isApproving={isApproving}
          assignedAdjustmentsList={assignedAdjustmentsList}
          assignedPaidTimeOffList={assignedPaidTimeOffList}
        />
        {isTrackingOpen && sessionInfo  &&
          <SessionMapModalComponent
            isOpen={isTrackingOpen}
            sessionInfo={sessionInfo}
            onMarkerClick={this.handleMarkerClick}
            onMarkerClose={this.handleMarkerClick}
            toggle={this.trackingToggle}
            selectedMarkerAddress={selectedMarkerAddress}
            geoFenceDetail={geoFenceDetail}
            isModalLoading={isTrackingModalLoading}
            toggleInfoWindow={this.toggleInfoWindow}
            isLocationLoading={isLocationLoading}
            resetModalData={this.resetModalData}
            getLocationAddress={this.getLocationAddress}
            permission={permission}
            userExtraData={this.props.userExtraData}
            getAddressForMarker = {this.getAddressForMarker}
            accessSidemenu={this.props.accessSidemenu}
          />
        }
        { isModalOpen &&
          <AdjustmentHourFormModalComponent
            isOpen={isModalOpen}
            toggle={this.toggleModal}
            dateFormat={dateFormat}
            adjustmentTypesList = { newAdjustmentTypeList }
            adjustmentTimecardList = {newAdjustmentTimecard}
            initialValues = {{adjustmentHour:newAdjustmentTimecard}}
            update={this.update}
            date={date}
            dateSelected={dateSelected}
            edit={idSelected ? true : false }
            adjustmentHourView={adjustmentHourView}
            location={location}
            custom
          />
        }
         {openMealModal &&
            <OverrideMealModal
              isOpen={openMealModal}
              toggle={this.toggleMealModal}
              closeModal={this.closePayMealModal}
              payMeal={this.payMeal}
              confirmMealStatusLoader = {this.state.confirmMealStatusLoader}
              rejectMealStatusLoader = {this.state.rejectMealStatusLoader}
            />
          }
          {this.state.isFeedbackModalOpen &&
            <FeedbackModal
              isOpen={this.state.isFeedbackModalOpen}
              toggle={this.feedbackModal}
              feedbackResponse={this.props.feedbackResponseList[this.state.questionNumber]}
              questionNumber={this.state.questionNumber}
              list = {this.props.feedbackResponseList}
              showPreviousOrNext={this.showPreviousOrNext}
              title={this.props.title}
              date = {this.state.DTDate}
              isFeedbackLoading={this.state.isFeedbackLoading}
              dateFormat={this.props.dateFormat}
              timeFormat={this.props.timeFormat}
              isSurveyLoading={this.state.isSurveyLoading}
            />
          }
          {openPtoModal &&
            <AddTimeOffTimecardModal
            isOpen={openPtoModal}
            toggle={this.togglePtoModal}
            employeeName={title}
            employeeId={params.id}
            oAuthToken={oAuthToken}
            company_id={company_id}
            fetchData={fetchData}
            selectedDate={this.state.selectedDate}
            ptoMode = {ptoMode}
            ptoPolicyId = {ptoPolicyId}
            updateState = {this.updateState}
            />
          }
          {doNotShowModal.isOpen &&
            <DoNotShowModal 
              isOpen={doNotShowModal.isOpen}
              message={doNotShowModal.message}
              toggle={this.closeDoNotShowModal}
              handleSubmit={this.handleDoNotShowMsg}
            />
          }
           {openPtoModal===false && ptoWarningModal === true &&
            <PtoWarningModal 
              isOpen={ptoWarningModal}
              message={ptoWarningMessage}
              toggle={this.closePtoWarningModal}
              handleSubmit={this.handleDoNotShowMsgPto}
            />
          }

          {openWarningModal &&
          <TimecardWarning
            isOpen={openWarningModal}
            toggle={this.toggleWarningModal}
            submitManualSession= {this.submitManualSession}
            updateState = {this.updateState}
          />
        }
      </section>
    );
  }
};

const mapStateToProps = state => ({
  dailyTimecardList: DailyTimecardDucks.dailyTimecardList(state),
  payPeriodTotal: DailyTimecardDucks.payPeriodTotal(state),
  location: RouteDucks.location(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  sessionInfo: ActivityTrackDucks.sessionInfo(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  adjustmentTimecardList: AdjustmentTimecardDucks.adjustmentTimecardList(state),
  activityLogs: ActivitySplitDucks.activityLogs(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  defaultPayrollSetting : ProfileDucks.defaultPayrollSetting(state),
  userExtraData: UserDucks.userExtraData(state),
  accessSidemenu: UserDucks.accessSidemenu(state),
  feedbackResponseList: DailyTimecardDucks.feedbackResponseList(state),
  dateFormatDisplay: BasicSettingDucks.dateFormatDisplay(state),
  userTimecardFilters : TimecardDucks.userTimecardFilters(state),
  userTimezone: UserDucks.userBATimezone(state),
  assignedAdjustmentsList: DailyTimecardDucks.assignedAdjustmentsList(state),
  assignedPaidTimeOffList:DailyTimecardDucks.assignedPaidTimeOffList(state)
  });

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  AdjustmentTimecardDucks: bindActionCreators(AdjustmentTimecardDucks, dispatch),
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

DailyTimecardListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  dailyTimecardList: PropTypes.array.isRequired,
  payPeriodTotal : PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  selectedMarkerAddress: PropTypes.string,
  sessionInfo: PropTypes.object,
  geoFenceDetail: PropTypes.object,
  SessionDucks: PropTypes.object.isRequired,
};

DailyTimecardListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DailyTimecardListSection);
