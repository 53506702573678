import React from "react";
import PropTypes from "prop-types";

import LayerAtom from "../../atoms/Layer";
import StorageGateway from "lib/storage-gateway";
import { toast } from "react-toastify";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */
class EDocsListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isInfoOpen: true,
      createGroupLayer: false,
      authToken: null,
      user_id: null,
      path: "",
      editSettings:false,
      nre_company_id: null,
      company_id: null,
      Type: null,
    };

    this.loadData = this.loadData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.closeLayer = this.closeLayer.bind(this);
    this.postMessageToChild = this.postMessageToChild.bind(this);
  }

  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }

  componentWillReceiveProps(props) {
    if (props.refreshFlag) {
      this.postMessageToChild("REFRESH_EDOCS_TAB");
      props.updateState("refrehEDocTabFlag", false);
    }
  }

  loadData() {
    this.postMessageToChild("REFRESH_EDOCS_TAB");
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "CLOSE_EDOCS_SLIDER") {
      toast.success(data?.content?.message);
      setTimeout(() => {
        this.setState({ createGroupLayer: false });
      }, 1000);
      this.loadData();
    } else if (message === "OPEN_EDOCS_SLIDER") {
      this.setState({
        createGroupLayer: true,
        path: data?.content?.group_id ?? "create",
        editSettings:data?.content?.editSettings ?? false,
      });
    } else if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "OPEN_EDOCS_SLIDER1") {
      this.setState({
        createGroupLayer: true,
        path: data?.content?.copy_id ?? "create",
        Type: "copy",
      });
    }
  }

  closeLayer(state) {
    if (state === "createGroupLayer") {
      this.setState({ createGroupLayer: false });
    }
    else {
      this.setState({ editDocumentLayer: false });
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    const oAuthToken = StorageGateway.get("graniteToken");
    const userID = StorageGateway.get("userId");
    const nre_company_id = StorageGateway.get("nre_company_id");
    const company_id = StorageGateway.get("company_id");
    this.setState({
      authToken: oAuthToken,
      user_id: userID,
      nre_company_id: nre_company_id,
      company_id: company_id,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  render() {
    const {
      createGroupLayer,
      editSettings,
      path,
      authToken,
      user_id,
      nre_company_id,
      company_id,
      Type,
    } = this.state;
    return (
      <div>
        <div
          style={{
            height: "calc(100vh - 102px - 100px)",
            marginRight: "-15px",
          }}
        >
          <iframe
            id="scheduling"
            title="worksana-nre"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={`${
              process.env.REACT_APP_WMAPPER_BASE_URL
            }/e-docs/?access_token=${authToken}&user_id=${user_id}&companyId=${company_id ||
              nre_company_id}`}
          />
        </div>

        {createGroupLayer && (
          <LayerAtom
            active={true}
            path={`/e-docs/${path}/?access_token=${authToken}&user_id=${user_id}&companyId=${company_id ||
              nre_company_id}&editSettings=${editSettings}`}
            setToggle={() => this.closeLayer("createGroupLayer")}
          />
        )}
      </div>
    );
  }
}

EDocsListPage.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  AdjustmentTypesDucks: PropTypes.object.isRequired,
};

EDocsListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default EDocsListPage;
