import React from "react";

import createHistory from "history/createBrowserHistory";
import { Provider as ReduxProvider } from "react-redux";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import ConnectedRouter from "react-router-redux/ConnectedRouter";

import ToastContainer from "react-toastify/lib/ToastContainer";

import configureStore from "helpers/configureStore";
import AccountsRoutes from "pages/accounts/Routes";
import ActivityRoutes from "pages/activities/Routes";
import EmployeeRoutes from "pages/employees/Routes";
import CustomerRoutes from "pages/customers/Routes";
import GenericsRoutes from "pages/generics/Routes";
import PayrollRoutes from "pages/payrolls/Routes";
import TimecardRoutes from "pages/timecards/Routes";
import VendorRoutes from "pages/vendors/Routes";
import ReportRoutes from "pages/reports/Routes";
import SchedulingRoutes from "pages/scheduling/Routes";
import businessAdminRoutes from "pages/businessAdmins/Routes";
import geoFenceRoutes from "pages/geoFences/Routes";
import JobScheduleRoutes from "pages/jobs/Routes";
import KnowledgeBaseRoutes from "pages/knowledgeBases/Routes";
import QualityAssuranceRoutes from "pages/qualityAssurance/Routes"
import BillingRoutes from "payments/bills/pages/Routes";
import SurveyRoutes from "surveys/pages/Routes";
import NotificationRoutes from "./notification/pages/Routes";
import CommunicationCenterRoutes from "./communicationCenter/pages/Routes";
import FormRoutes from "forms/pages/Routes";
import LearningCenterRoutes from "learningCenter/pages/Routes";
import SmartConnectRoutes from "pages/smartConnect/Routes";

import PtoRoutes from "pages/pto/Routes";
import ApplicantRoutes from "applicants/pages/Routes";
import MMroutes from "pages/maintenanceMode/MMroutes";

import "styles/index.global.scss";
import "airbnb-js-shims";
const store = configureStore(
  // Server side rendering would have mounted our state on this global.
  window.__APP_STATE__ // eslint-disable-line no-underscore-dangle
);
const history = createHistory();

const App = () => (
  <ReduxProvider store={store}>
    <ConnectedRouter history={history}>
      <section>
        <ToastContainer hideProgressBar />
        <Switch>
          {/* <Route path="/" component={MMroutes} /> */}
        <Route path="/accounts" name="accounts" component={AccountsRoutes} />
          <Route path="/surveys" name="surveys" component={SurveyRoutes} />
          <Route path="/employee" name="employee" component={EmployeeRoutes} />
          <Route path="/vendor" name="vendor" component={VendorRoutes} />
          <Route path="/payroll" name="payroll" component={PayrollRoutes} />
          <Route path="/timecard" name="timecard" component={TimecardRoutes} />
          <Route path="/activity" name="activity" component={ActivityRoutes} />
          <Route path="/report" name="report" component={ReportRoutes} />
          <Route
            path="/business-admin"
            name="business-admin"
            component={businessAdminRoutes}
          />
          <Route path="/geofence" name="geofence" component={geoFenceRoutes} />
          <Route path="/job" name="job" component={JobScheduleRoutes} />
          <Route
            path="/customers"
            name="customers"
            component={CustomerRoutes}
          />
          <Route
            path="/knowledge-base"
            name="knowledge-base"
            component={KnowledgeBaseRoutes}
          />
          <Route
          path="/quality-assurance"
          name="quality-assurance"
          component={QualityAssuranceRoutes}
          />
           <Route
          path="/smart_connector"
          name="dashboard"
          component={SmartConnectRoutes}
          />
          <Route path="/bill" name="bill" component={BillingRoutes} />

          <Route
            path="/scheduling"
            name="scheduling"
            component={SchedulingRoutes}
          />
          <Route path="/pto-policy" name="pto" component={PtoRoutes} />
          <Route
            path="/communication"
            name="communication"
            component={NotificationRoutes}
          />
          <Route
            path="/document"
            name="document"
            component={CommunicationCenterRoutes}
          />
          <Route
            path="/learning"
            name="learning"
            component={LearningCenterRoutes}
          />
          <Route path="/forms" name="forms" component={FormRoutes} />
          <Route
            path="/applicant"
            name="applicant"
            component={ApplicantRoutes}
          />
          <Route path="/" component={GenericsRoutes} />
         
        </Switch>
      </section>
    </ConnectedRouter>
  </ReduxProvider>
);

export default App;
