import React from "react";
import TwoColumnLayout from "layouts/TwoColumn";

import { NAV } from "constants/smartConnect";
import SmartConnectList from "./SmartConnectList";

const SmartConnectListPage = () => {
  
  return (
    <TwoColumnLayout
      navInfo={NAV.SmartConnectorListPage}
    >
        <SmartConnectList/>
      
    </TwoColumnLayout>
  );
};

export default SmartConnectListPage;
