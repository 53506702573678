import React from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as VendorDucks from 'ducks/vendors/vendor';
import * as InfoDucks from 'ducks/info/info';
import * as BusinessAdminDucks from 'ducks/businessAdmins/businessAdmin';
import CustomAttributeToggler from "components/common/CustomAttributeToggler";
import { toast } from 'react-toastify';

import TwoColumnLayout from 'layouts/TwoColumn';
import VendorFilterSection from 'sections/vendors/list/Filter';
import VendorListSection from 'sections/vendors/list/List';
import PaginationComponent from 'components/common/Pagination';
import { NAV } from 'constants/vendors';
/**
 * VendorListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link VendorFilterSection}
 *    - {@link VendorListSection}
 *
 * Fetch Needs:
 *    - getVendors
*/
class VendorListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = { isLoading: false, isInfoOpen: true, commercialStatusList : [] , isOpen : false, isStatus: null, isSubmit: null, showableAttributes: {}, attributeToggler: false, selectedCompany: null, customSettingLoading: false, buttonLoading: false};
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setTimeOut = this.setTimeOut.bind(this);
    this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.toggle = this.toggle.bind(this);
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.setPermission = this.setPermission.bind(this);
    this.accessorToHeader = {
      work_shift : 'Work Shift',
      mileage: "Mileage",
      ENABLE_BUGSEE : 'Bugsee', //it is a system setting and this is the label
      feedback : 'Feedback',
      ENABLE_ADVANCE_PAYROLL_SETTINGS: 'Advance Payroll Settings',
      meal_questionnaire: 'Meal Questionnaire',
      nosql: 'Nosql',
      paid_time_off:'PTO (Paid Time Off)',
      MIGRATE_TO_WORKSANA_OFFLINEFIRST_APP:"Migrate To Worksana Offline First App",
      applicant_center: "Applicant Center",
      learning_center: "Learning Center",
      communication_center: "Communication Center",
      nosql_lambda: "Bypass API Server",
      mobile_force_punch_out_enabled: "Mobile Force Punch Out",
      meal_adjustments: "Meal Adjustments",
      new_dashboard: "New Dashboard",
    };
  }

  componentWillMount() {   
    const cookieKey = cookie.load("vendor_list_all");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const {history, location: { query: prevQuery , pathname} } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if(!('is_active' in nextQuery)){
      history.push({
        pathname,
        search: queryString.stringify({
          is_active: true,
          start_date: moment().subtract( 6, "days").format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
        }),
      });
    }
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  setTimeOut(){
    this.setState({ isLoading: true });
    setTimeout(() => { 
      this.setState({ isLoading: false });
    }, 1000);
  }

  toggle(status) {
    const { isOpen } = this.state;
    this.setState({ isStatus : status });
    this.setState({ isOpen : !isOpen });
  }

  updateActiveStatus(status) {
    const { router: { history } } = this.context;
    this.setState({ isSubmit: true , isLoading : true});
    const {  vendorSelectedList } = this.props;
    const { location: { query, pathname } } = this.props;
    this.props.VendorDucks.postActiveStatus({
      is_active: status, company_ids: vendorSelectedList,
    })
      .then(() => {
        history.push({
          pathname,
          search: queryString.stringify({
            ...query, page: 1
          }),
        });
        this.setState({ isSubmit: false});
        this.toggle();
      })
      .catch(() => {
        this.setState({ isSubmit: false });
        this.toggle();
      });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_list_all", !isInfoOpen);
  }

  fetchData(params) {
    let detail =  { ...params };
    this.setState({ isLoading: true });
    const pageSize = cookie.load('vendor_page_size');
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.list.all'}),
      this.props.VendorDucks.getVendors({...detail, page_size: pageSize}),
      this.props.BusinessAdminDucks.getBusinessAdmin(),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    const { location: { query, pathname } } = this.props;
    const { router: { history } } = this.context;
    if (!('is_active' in query) || !('start_date' in query) || !('end_date' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          is_active: true,
          start_date: moment().subtract( 6, "days").format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          ordering:"-create_date"
        }),
      });
    } else {
      this.fetchData(query);
    }
  }

  setAttributeToggler(bool, data) {
    this.setState({ attributeToggler: bool });
    if (bool) {
      this.setState({selectedCompany: data.id });
      this.setState({customSettingLoading: true});
      this.props.VendorDucks.getCustomSetting({ company_id: data.id, paginate: false})
      .then((res) => {
        let obj = {};
        if( res && res.value.length) {
          (res.value).forEach((item) => obj[item.name] = item.enable);
        } 
        this.setAttributes(obj);
        this.setState({customSettingLoading: false});
      });
    } else {
      this.setState({selectedCompany: null,  showableAttributes: {} });
    }
  }

  /*function which calls on changing the toggle buttons of custom button*/
  setAttributes(attributes) {
    console.log("atributes",attributes)
    this.setState({ showableAttributes: attributes })
  }

  //only for mileage permission, if other permission will add, map other conditions also
  setPermission(){
    this.setState({buttonLoading: true})
    const {showableAttributes} = this.state;
    let permissions = [];
    Object.keys(showableAttributes).forEach(key => {
      permissions.push({name: key, enable: showableAttributes[key]})     
    });
    if(showableAttributes.length !== 0) {
      this.props.VendorDucks.postCustomSetting({permissions, company_id: this.state.selectedCompany})
          .then(() => {
            this.setState({buttonLoading: false})
            this.setState({ attributeToggler: !this.state.attributeToggler });
            toast.success("Custom Setting Updated Successfully!");
          })
          .catch((error) =>{
            this.setState({buttonLoading: false})
            toast.error(error?.non_field_errors[0]);
            this.setState({ attributeToggler: !this.state.attributeToggler });
          })
    } else {
      this.setState({ attributeToggler: !this.state.attributeToggler });
    }
  }

  render() {
    const { isLoading, isInfoOpen, commercialStatusList, customSettingLoading} = this.state;
    const { infoList, vendorCount } = this.props;
    return (
      <TwoColumnLayout
        navInfo={NAV.VendorListPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <VendorFilterSection 
          commercialStatusList={
            commercialStatusList
          }
          updateActiveStatus = {this.updateActiveStatus}
          isStatus={this.state.isStatus}
          isSubmit={this.state.isSubmit}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        />
        <VendorListSection 
          isLoading={isLoading}
          history={this.props.history}
          setAttributeToggler = {this.setAttributeToggler}
          attributeToggler = {this.state.attributeToggler}
        />
        {this.state.attributeToggler && 
          <CustomAttributeToggler
            setToggle={() => this.setAttributeToggler(!this.state.attributeToggler)}
            toggle={this.state.attributeToggler}
            data={{
              labels: Object.values(this.accessorToHeader),
              accessors: Object.keys(this.accessorToHeader),
              defaultVals: this.state.showableAttributes
            }}
            onChange={this.setAttributes}
            setPermission={this.setPermission}
            buttonLoading={this.state.buttonLoading}
            isLoading={customSettingLoading}
          />}
        {vendorCount && (vendorCount >0) ? 
          <PaginationComponent
            count={this.props.vendorCount}
            location={this.props.location}
            history={this.props.history}
            cookieKey="vendor_page_size"
          />
          :
          null
        }
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  vendorSelectedList: VendorDucks.vendorSelectedList(state),
  vendorCount: VendorDucks.vendorCount(state),
});

const mapActionsToProps = dispatch => ({
  BusinessAdminDucks: bindActionCreators(BusinessAdminDucks, dispatch),
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

VendorListPage.propTypes = {
  VendorDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

VendorListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorListPage);
