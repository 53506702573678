import React from 'react';

import PropTypes from 'prop-types';

import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Input from 'reactstrap/lib/Input';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Label from 'reactstrap/lib/Label';

const FileBrowserAtom = (props) => {
  const {
    input, layout, label, prefix, suffix, help,
    onChange, meta: { touched, error }, image,file, ...rest
  } = props;

  const handleChange = (val) => {
    input.onChange(val);
    if (onChange) onChange(val);
  };

  return (
    <FormGroup
      row={Object.keys(layout).length > 0}
      color={touched && error ? 'danger' : ''}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0
        ? (<Col {...layout.wrapperCol}>
          <InputGroup>
            {prefix && (<InputGroupAddon>{prefix}</InputGroupAddon>)}
            <label htmlFor={rest.id} className="custom-file">
              <Input
                {...rest}
                name={input.name}
                className="custom-file-input"
                onChange={handleChange}
              />
              {image
                ? (<span
                  className="btn-primary file-browser"
                >
                  <img
                    src={image}
                    alt="Icon"
                    height="18"
                    width="18"
                  />
                </span>)
                : (<span className="custom-file-control">{file !== null && input.value && input.value[0] && input.value[0].name ? input.value[0].name : "Choose File..."}</span>)
              }
            </label>
            {suffix && (<InputGroupAddon>{suffix}</InputGroupAddon>)}
          </InputGroup>
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
        : (<span>
          <InputGroup>
            {prefix && (<InputGroupAddon>{prefix}</InputGroupAddon>)}
            <label htmlFor={rest.id} className="custom-file">
              <Input {...rest} className="custom-file-input" />
              {image
                ? (<span
                  className="btn-primary"
                  style={{
                    padding: "10px",
                    borderRadius: "4px",
                    height: "38px",
                    width: "38px",
                    position: "absolute",
                    top: "0px",
                  }}
                >
                  <img
                    src={image}
                    alt="Icon"
                    height="18"
                    width="18"
                  />
                </span>)
                : (<span className="custom-file-control">{file !== null && input.value && input.value[0] && input.value[0].name ? input.value[0].name : "Choose File..."}</span>)
              }
            </label>
            {suffix && (<InputGroupAddon>{suffix}</InputGroupAddon>)}
          </InputGroup>
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </span>)
      }
    </FormGroup>
  );
};

FileBrowserAtom.defaultProps = {
  layout: {},
};

FileBrowserAtom.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  layout: PropTypes.object,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
  label: PropTypes.string,
  help: PropTypes.string,
};

export default FileBrowserAtom;
