import { server } from "helpers/config";

const SERVER_URL = server.apiHost;
const KONNECT_URL = server.konnectHost;

export default {
  TIMECARD: `${SERVER_URL}/timecards/`,
  CUSTOM_TIMECARD: `${SERVER_URL}/daily-timecards/`,
  CUSTOM_DAILY_TIMECARD: `daily-timecards/v2/`,
  DAILY_TIMECARD: `daily-timecard/`,
  HISTORIC_TIMECARDS: `historic-daily-timecards/`,
  DEAVIATION: `deviation/`,
  EXPORT: "export/",
  EXPORT_CSV: "csv/",
  EXPORT_PDF: "pdf/",
  FAMOUS_EXPORT: "famous-payroll/",
  KONNECT_TIMECARD: `${KONNECT_URL}/core/get-timecards/`,
  TIMECARD_SYNC: `${KONNECT_URL}/metc/retry-timecards/`,
  APPROVE_TIMECARD: "approve/",
  ADJUSTMENT_TYPES_TIMECARD: `${SERVER_URL}/timecards/daily-timecards/adjustment/`,
  ACTIVITY_DEAVIATION: `${SERVER_URL}/activity/activity-splits/`,
  DATE_RANGE_EXPORT: `payroll-extraction/export`,
  EXPORT_PDF_XLS: `employee-timesheet/export/`,
  JOB_PAYROLL_REPORT: "job-costing-payroll-report/export/",
  DATA_TECH_REPORT: "data-tech/export/",
  Job_PAYROLL_DETAIL_REPORT: "job-costing-payroll-details-report/export/",
  SOUTH_EAST_EXPORT: "job-costing-payroll-report/south_east/export/",
  MIKE_ROVNER_EXPORT: "mike-rovner/export/",
  ADP_REPORT: "adp/export/",
  DEPARTMENT_LIST: `${SERVER_URL}/company/departments`,
  EXPORT_PAY_DATA_CSV: "tbs/paydata/export/",
  EXPORT_MILEAGE_CSV: "tbs/mileage/export/",
  DOWNLOAD_TBS_CSV: "tbs/billing-system/export/",
  ACTIVITY: `${SERVER_URL}/activity/`,
  USER_PREFERENCE: "user_preference/view/",
  BATCH_APPROVE: "batch-approve/",
  USER_FILTERS: `${SERVER_URL}/t2b/accounts/user_preference/view/`,
  FEEDBACK: `${SERVER_URL}/feedback/response/`,
  AUDIT_SUMMARY_EXPORT: "audit-summary/async-export/",
  ADJUSTMENT_SUMMARY_EXPORT: "adjustment-summary/export/",
  EXPORT_SAGE_CSV: "sage-export/",
  EXPORT_ISOLVED: "isolved/async-export/",
  MMCHR_EXPORT: "mmchr/async-export/",
  CUSTOM_KONECT_TIMECARD: `${KONNECT_URL}/core/daily-timecards/`,
  EXPORT_KONNECT_PDF: "pdf-export/",

  IIF_REPORT: "iif-export",
  ADP_REPORT_V2: "v2/",

  MEALS_FLAG_REPORTS : 'meal-flags/export/',
  FENCE_FACTORY_REPORT: 'fence-factory/export/',
  PTO_BALANCE_EXPORT: `${SERVER_URL}/pto/policy/export/`,
 
  JOB_CASTING_SUMMARY_BASE_URL: `job-costing-payroll-report/export/pdf2/`,
  JOB_CASTING_DETAILS_BASE_URL: `data/job-costing-payroll-details-report/export/pdf2/`,
  PAYLOCITY_EXPORT: `paylocity/`,
  
};
