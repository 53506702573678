import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import QualityAssuranceListPage from 'pages/qualityAssurance/index';


const QualityAssuranceRoutes = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="knowledge_base.list"
        component={QualityAssuranceListPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

QualityAssuranceRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default QualityAssuranceRoutes;
