export const STRINGS = {
  UPLOAD_ERROR_TOAST_MESSAGE: "File type not supported",
  SUBMIT_ERROR_TOAST_MESSAGE: "Please select a valid file to upload",
  UPLOAD_SUBMIT_TOAST_MESSAGE: "File uploaded successfully",
  BROWSE_LABEL_WEB: "Browse the file path (File should be in .csv format ONLY)",
  BROWSE_LABEL: "Browse the file path (File should be in .csv or .apk format ONLY)",
  ROOT_LABEL: "test-automation-vault-bucket",
  FOLDER_LABEL_WEB: "test-automation-vault-bucket/WEB_LITMUS_RESULTS_CSV",
  ROOT_LABEL_WEB: "WEB_LITMUS_RESULTS_CSV",
  ROOT_LABEL_MOBILE: "MOBILE_LITMUS_RESULTS_CSV",
  FOLDER_LABEL_MOBILE: "test-automation-vault-bucket/MOBILE_LITMUS_RESULTS_CSV",
};
