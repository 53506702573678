const baseNav = [
    { href: '/dashboard', label: 'Dashboard' },
    { href: '/smart_connector/list', label: 'Smart Connector' },
  ];
  
  export const NAV = {
    SmartConnectorListPage: {
      title: 'Smart Connector',
      breadcrumb: [
        ...baseNav,
        { label: 'All' },
      ],
      description: 'Smart Connector',
    },
  };
  
 
  export default {NAV};
  