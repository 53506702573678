import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import CorrectionActivitySplitTable from 'components/reports/correctionSummaryReport/CorrectionActivitySplitTable';

/**
 * ManualCorrectionSummaryListSection -> ActivitySplitListSection
 *
 * Components:
 *    - {@link ActivitySplitTableComponent}
 *    - {@link PaginationComponent}
 *
 * State:
 *    - reportData
 *
 * Actions:
 *    None
 */
const CorrectionActivitySplitList = (props) => {
  const { data, isLoading, location} = props;
  const {pathname,query} = location;
  const cookieData = cookie.load("activity_split_page_size");
  let pageSize;

  if (query && query.page_size)  pageSize = +query.page_size;
  else if(cookieData) pageSize = +cookieData;
  else pageSize = 10;

  const navigation = (item)=>{
    
    const parsedQuery =JSON.parse(query.reportDetail)
    const parentObj = {
      start_date: parsedQuery.start_date,
      end_date: parsedQuery.end_date,
      payroll_id: 'custom',
      employee_id: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      custom : true,
      show_employee_list : false,
      empployee_dept_name: item?.department_name,
      employee_team_name: item?.team_name,
      employee_title: item?.employee_title,
      employee_id_show: item?.employee_id,

    };
    //const url = `/activity/timecard/custom/list/${item.daily_timecard_id}/split?${queryString.stringify(parentObj)}`;
    const url = `/timecard/custom/list/${item.id}/daily?${queryString.stringify(parentObj)}`;
    return window.open(url, '_blank');
  }
  if (isLoading) return <SectionLoaderAtom active />;
  return (
    <section>
      <CorrectionActivitySplitTable
        data={data}
        location={location}
        pageSize={pageSize}
        navigation={navigation}
      />
      <PaginationComponent
        count={data.length}
        location={location}
        cookieKey="activity_split_page_size"
      />
    </section>
  );
};

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({});

CorrectionActivitySplitList.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

CorrectionActivitySplitList.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CorrectionActivitySplitList);
