import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import { STRINGS } from "pages/qualityAssurance/components/Strings";
import SectionLoaderAtom from "atoms/SectionLoader";
import {
  getResults,
  startTesting,
  testResponse,
  getS3BucketLink,
  uploadLink,
} from "qualityAssurance/controllers/qualityAssurance";

let webResponse, mobileResponse;

const useQualityAssurance = () => {
  const [treesData, setTreesData] = useState([]);
  const [links, setLink] = useState([]);
  const [status, setStatus] = useState("NOT-STARTED");
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Web");
  const [treeData, setTreeData] = useState(treesData);
  const [issOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState();
  const [file, setFile] = useState(null);
  const [isDataChecked, setIsDataChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [extension, setExtension] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  /**
   * Toggles the selected platform between "Web" and "Mobile".
   */
  const toggleSwitch = () => {
    setSelected((prevOption) => (prevOption === "Web" ? "Mobile" : "Web"));
    setIsActive(!isActive);
  };

  

  /**
   * Creates a tree structure from an array of URLs.
   * @param {string[]} urls - Array of URLs to create the tree structure from.
   * @returns {Object} - The root node of the tree structure.
   */
  const createTreeData = (urls) => {
    let idCounter = 0;
    let idRootCounter = 0;

    const createNode = (name) => ({
      id: `${idRootCounter}-${idCounter++}`,
      name,
      isOpen: false,
      children: [],
    });

    const buildTree = (paths, node, originalUrl) => {
      if (paths.length === 0) {
        node.url = originalUrl;
        return;
      }

      const [currentFolder, ...remainingPaths] = paths;
      let childNode = node.children.find((child) => child.name === currentFolder);

      if (!childNode) {
        childNode = createNode(currentFolder);
        node.children.push(childNode);
      }

      buildTree(remainingPaths, childNode, originalUrl);
    };

    const root = createNode(STRINGS.ROOT_LABEL);
    const webLitmusNode = createNode(
      selected === "Web" ? STRINGS.ROOT_LABEL_WEB : STRINGS.ROOT_LABEL_MOBILE
    );

    root.children.push(webLitmusNode);

    urls.forEach((url) => {
      let path = url.split("/").slice(5);
      path = path.map((part) => part.split("?")[0]);

      buildTree(path, webLitmusNode, url);
    });

    return root;
  };



  /**
   * Starts a test based on the selected platform and updates the test status.
   * @param {string} data - Determines the test start behavior. Can be "Initial", "Refresh", or any other string.
   */
  const startTest = async (data) => {
    const params = { platform: selected };
    setIsLoading(true);

    try {
      if (data !== "Initial" && data !== "Refresh") {
        if (selected === "Mobile") {
          mobileResponse = await startTesting(params);
        } else {
          webResponse = await startTesting(params);
        }
      }

      if (selected === "Mobile") {
        if (mobileResponse === undefined) {
          setStatus("NOT_STARTED");
        } else {
          const status = await testResponse(
            mobileResponse?.data?.build_id
          );
          setStatus(status?.data?.build_status);
        }
      } else {
        if (webResponse === undefined) {
          setStatus("NOT_STARTED");
        } else {
          const status = await testResponse(
            webResponse?.data?.build_id
          );
          setStatus(status?.data?.build_status);
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };



  /**
   * Fetches test results and updates the tree data.
   */
  const fetchData = async () => {
    setTableLoader(true);
    const params = { platform: selected };
    try {
      const response = await getResults(params);
      const rootFolderName =
        selected === "Web" ? STRINGS.FOLDER_LABEL_WEB : STRINGS.FOLDER_LABEL_MOBILE;
      setLink(response);
      const newTreesData = response
        ? [createTreeData(response, rootFolderName)]
        : [];

      setTreesData(newTreesData);
    } catch (error) {
      console.error("API Error:", error);
    }
    finally{

      setTableLoader(false);
  
      }
  };



  /**
   * Toggles the visibility of the selected URL.
   * @param {string} url - The URL to toggle.
   */
  const toggle = (url) => {
    setUrl(url);
    setLoading(true);
    setIsOpen(!issOpen);
  };

  /**
   * Handles the file download by creating and clicking a hidden link element.
   * @param {string} url - The URL of the file to download.
   */
  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  /**
   * Toggles the open/close state of a tree node.
   * @param {Object} node - The tree node to toggle.
   */
  const handleToggle = (node) => {
    const updateNodeState = (nodes) => {
      return nodes.map((n) => {
        if (n.id === node.id) {
          n.isOpen = !n.isOpen;
        } else if (n.children) {
          n.children = updateNodeState(n.children);
        }
        return n;
      });
    };

    setTreeData(updateNodeState(treeData));
  };



  /**
   * Handles file selection and validation, and sets the selected file.
   * @param {Event} e - The file input change event.
   */
  const handleFileSelect = (e) => {
    const files = e.target.files[0];
    const reader = new FileReader();
    const fileExt = files && files.name.split(".").pop();
    const docxExt = ["csv", "apk"];
    const docxExtWeb = ["csv"];

    if (selected === "Web") {
      if (docxExtWeb.indexOf(fileExt) > -1) {
        setFileType("Document");
      } else {
        setFile(null);
        e.target.files = undefined;
        return toast.error(STRINGS.UPLOAD_ERROR_TOAST_MESSAGE);
      }
    } else {
      if (docxExt.indexOf(fileExt) > -1) {
        setFile(files);
      } else {
        setFile(null);
        return toast.error(STRINGS.UPLOAD_ERROR_TOAST_MESSAGE);
      }
    }
    reader.onload = () => setFile(files); setLogoUrl(reader.result);
    if (files) reader.readAsDataURL(files);
    return true;
  };



  /**
   * Handles form submission to upload the selected file.
   * @param {Event} event - The form submit event.
   */
  const handleSubmit = async (event, reset) => {
    event.preventDefault();
    let userData = new FormData();
    userData.append("file", file);
  
    if (!file) {
      return toast.error("No file selected!");
    }
  
    try {
      const params = {
        platform: selected,
        operation: "upload_file",
        filename: file.name,
        is_locator_file: isDataChecked,
      };
  
      const response = await getS3BucketLink(params);
      const { fields, url } = response.data;
  
      const fileData = new FormData();
      Object.keys(fields).forEach((field) => {
        fileData.append(field, fields[field]);
      });
      fileData.append("file", file);
  
      // Perform the file upload
      await uploadLink({ url, fileData });
  
      // Reset the form after successful upload
      setFile(null);
      reset();
      document.getElementById('test').value = null; // Clear the file input
  
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error(error);
      setFile(null);
      reset();
      document.getElementById('test').value = null; // Clear the file input
      toast.error("Error during file upload");
    }
  };
  



  /**
   * Handles the change event of the checkbox for data checking.
   * @param {Event} e - The checkbox change event.
   */
  const handleCheckboxChange = (e) => {
    setIsDataChecked(e.target.checked);
  };


  const getFileExtension = (fileUrl) => {
    // Remove query parameters from URL (anything after ?)
    const cleanUrl = fileUrl.split('?')[0];
    // Extract the file extension from the cleaned URL
    return cleanUrl.split('.').pop().toLowerCase();
  };

  const getPreviewContent = (fileUrl) => {
    const fileExtension = getFileExtension(fileUrl);
    setExtension(fileExtension);

    if (fileExtension === "csv") {
      const googleSheetsViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
        fileUrl
      )}&embedded=true`;
      return (
        <>
          {loading ? <SectionLoaderAtom active /> : <></>}
          <iframe
            src={googleSheetsViewerUrl}
            width="100%"
            style={{ marginTop: "1rem" }}
            height="400"
            frameBorder="0"
            onLoad={() => setLoading(false)}
          ></iframe>
        </>
      );
    }

    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
    return <img src={fileUrl} alt="Preview" className="image-preview" width="100%" height="auto" style={{marginTop:"0.5rem"}} />;
    }

    if (fileExtension === "mp4") {
      return (
        <video controls className="video-preview" style={{aspectRatio:"0.45/1",width:"100%",marginTop:"0.5rem"}}>
          <source src={fileUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return <p>Preview not available for this file type.</p>;
}

  useEffect(() => {
    fetchData();
    startTest("Initial");
  }, [selected]);

  useEffect(() => {
    setTreeData(treesData);
  }, [treesData]);

  return {
    treesData,
    tableLoader,
    links,
    status,
    isLoading,
    isActive,
    selected,
    treeData,
    issOpen,
    url,
    file,
    isDataChecked,
    extension,
    toggleSwitch,
    fetchData,
    startTest,
    toggle,
    handleDownload,
    handleToggle,
    handleCheckboxChange,
    handleFileSelect,
    handleSubmit,
    getPreviewContent
  };
};

export default useQualityAssurance;
