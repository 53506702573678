import React from "react";
import TwoColumnLayout from "layouts/TwoColumn";
import TestUpload from "pages/qualityAssurance/components/TestUpload";
import FolderTreeTable from "pages/qualityAssurance/components/FolderTreeTable";
import useQualityAssurance from "pages/qualityAssurance/hooks/useQualityAssurance"; // Import the hook
import { NAV } from "constants/qualityAssurance";

const QualityAssuranceListPage = () => {
  const {
    status,
    tableLoader,
    isLoading,
    isActive,
    selected,
    toggleSwitch,
    fetchData,
    startTest,
    treeData,
    issOpen,
    file,
    url,
    extension,
    toggle,
    handleDownload,
    handleToggle,
    isDataChecked,
    handleCheckboxChange,
    handleFileSelect,
    handleSubmit,
    getPreviewContent,
  } = useQualityAssurance();

  return (
    <TwoColumnLayout
      navInfo={NAV.QualityAssuranceListPage}
      fetchData={fetchData}
    >
      <TestUpload
        startTest={startTest}
        status={status}
        isLoading={isLoading}
        fetchData={fetchData}
        toggleSwitch={toggleSwitch}
        isActive={isActive}
        selected={selected}
        isDataChecked={isDataChecked}
        handleCheckboxChange={handleCheckboxChange}
        handleFileSelect={handleFileSelect}
        tableLoader={tableLoader}
        handleSubmit={handleSubmit}
        file={file}
      />
      <FolderTreeTable
        startTest={startTest}
        treeData={treeData}
        issOpen={issOpen}
        url={url}
        toggle={toggle}
        handleDownload={handleDownload}
        handleToggle={handleToggle}
        getPreviewContent={getPreviewContent}
        selected={selected}
        tableLoader={tableLoader}
        extension={extension}
      />
    </TwoColumnLayout>
  );
};

export default QualityAssuranceListPage;
