import React, { useState, useEffect } from "react";
import Table from "reactstrap/lib/Table";
import DownloadImage from "atoms/PasswordStrengthMeter/Checkbox_Green.png";
import Link from "react-router-dom/Link";
import SectionLoaderAtom from 'atoms/SectionLoader';

import PreviewModal from "pages/qualityAssurance/components/PreviewModal";

import "pages/qualityAssurance/css/FolderStructure.css";

const FolderTreeTable = ({
  treeData,
  issOpen,
  url,
  toggle,
  handleDownload,
  handleToggle,
  startTest,
  getPreviewContent,
  selected,
  tableLoader,
  extension
}) => {
  const renderRows = (nodes, level = 0) => {
    return nodes.map((node) => (
      <React.Fragment key={node.id}>
        <tr className="folder-row">
          <td
            className="folder-cell"
            style={{
              paddingLeft: `${level === 0 ? 10 : level * 20}px`,
            }}
          >
            {!!node.children.length && (
              <span
                onClick={() => handleToggle(node)}
                className="folder-toggle"
              >
                {node.isOpen ? "[-]" : "[+]"}
              </span>
            )}
            {!node.children.length ? (
              <Link
                to={{ pathname: "" }}
                onClick={() => toggle(node.url)}
                className="folder-link"
              >
                {node.name}
              </Link>
            ) : (
              <span className="folder-link">{node.name}</span>
            )}
            {!node.children.length && (
              <img
                src={DownloadImage}
                alt="Icon"
                onClick={() => handleDownload(node.url)}
                className="download-icon"
              />
            )}
          </td>
        </tr>
        {node.isOpen && node.children && renderRows(node.children, level + 1)}
      </React.Fragment>
    ));
  };

  return (
    <>
    {tableLoader ? <SectionLoaderAtom active/>:
      <div className="table-wrapper">
        <div className="table-responsive" style={{ marginLeft: "1rem" }}>
          <Table
            responsive
            hover
            size="sm"
            className="mt-1 table-timecard-main"
          >
            <thead className="t-head">
              <tr className="Header-column">
                <th>
                  <span>{`Folder Name ${selected==="Web" ? "(Web)" : "(Mobile)"}`}</span>
                  <span>
                    <button className="testButton" onClick={startTest}>
                      Start Test
                    </button>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="t-body">{renderRows(treeData)}</tbody>
          </Table>
        </div>
      </div>
}
      {issOpen && <PreviewModal isOpen={issOpen} toggle={toggle} url={url} extension={extension} getPreviewContent={getPreviewContent} />}
    </>
  );
};
export default FolderTreeTable;
