import axios from "axios";

/**
 * Fetches the test results from the backend API.
 * @param {Object} params - Contains the platform type (e.g., "Web" or "Mobile").
 * @returns {Object} - The API response data.
 */
const getResults = async (params) => {
  const response = await axios.post(
    "https://k33tp4o3jauxrvy5xttoflqbfe0bakip.lambda-url.us-west-2.on.aws/",
    { platform: params.platform },
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
};



/**
 * Initiates a new test for the selected platform.
 * @param {Object} params - Contains the platform type (e.g., "Web" or "Mobile").
 * @returns {Object} - The API response indicating test initiation.
 */
const startTesting = async (params) => {
  const response = await axios.post(
    "https://d6tjypuyywdku3pxrlqnsjw7aq0vpwbh.lambda-url.us-west-2.on.aws/",
    {
      platform: params.platform,
      operation: "start_test",
      project_name: "Test-Automation-Vault",
    },
    { headers: { "Content-Type": "application/json" } }
  );
  return response;
};



/**
 * Retrieves the status of the test execution based on the build ID.
 * @param {string} params - The build ID of the test run.
 * @returns {Object} - The API response containing the current build status.
 */
const testResponse = async (params) => {
  const response = await axios.post(
    "https://d6tjypuyywdku3pxrlqnsjw7aq0vpwbh.lambda-url.us-west-2.on.aws/",
    {
      operation: "status",
      project_name: "Test-Automation-Vault",
      build_id: params,
    },
    { headers: { "Content-Type": "application/json" } }
  );
  return response;
};



/**
 * Requests a pre-signed S3 bucket link from the API for file uploads.
 * @param {Object} params - Contains platform type, operation type, filename, and optional locator file flag.
 * @returns {Object} - The API response containing the S3 pre-signed URL and form fields for the upload.
 */
const getS3BucketLink = async (params) => {
  const response = await axios.post(
    "https://d6tjypuyywdku3pxrlqnsjw7aq0vpwbh.lambda-url.us-west-2.on.aws/",
    {
      platform: params.platform,
      operation: "upload_file",
      filename: params.filename,
      is_locator_file: params.is_locator_file,
    },
    { headers: { "Content-Type": "application/json" } }
  );
  return response;
};



/**
 * Uploads a file to the S3 bucket using the provided pre-signed URL.
 * @param {Object} params - Contains the S3 URL and the file data for uploading.
 */
const uploadLink = async (params) => {
  await axios.post(params.url, params.fileData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export default {
  getResults,
  startTesting,
  testResponse,
  getS3BucketLink,
  uploadLink,
};
