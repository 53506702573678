//Library Imports
import React from "react";

import PropTypes from "prop-types";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Col } from "reactstrap";
import { Row } from "reactstrap";
import Switch from "react-switch";

//Ducks Imports
import * as DepartmentDucks from "ducks/vendors/department";
import * as OnboardDucks from "ducks/vendors/onboard";
import * as EmployeeDucks from "ducks/employees/employee";
import * as ReportDucks from "ducks/reports/report";
import * as SystemSettingDucks from "ducks/vendors/systemSetting";
import * as UserDucks from "ducks/accounts/user";
import * as InfoDucks from "ducks/info/info";
import * as GeoFenceDucks from "ducks/geoFences/geoFence";
import * as TimecardDucks from "ducks/timecards/timecard";

import RefreshIconAtom from "atoms/RefreshIcon";
import TwoColumnLayout from "layouts/TwoColumn";
import DashboardOnboardListSection from "sections/generics/dashboardOnboard/List";
import DashboardListSection from "sections/generics/dashboard/List";
import NewDashboard from "sections/generics/dashboard/NewDashboard";
import SectionLoaderAtom from "atoms/SectionLoader";

import { NAV } from "constants/employees";

class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.updateState = this.updateState.bind(this);
    this.state = {
      newDashboard: false,
    };
  }

  componentWillMount() {
    const { joyRidePerms } = this.props;
    this.setState({
      isLoading: false,
      isInfoOpen: true,
      ...joyRidePerms,
    });
    const cookieKey = cookie.load("dashboard_info");
    if (cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }

    this.props.InfoDucks.getInformations({ route: "dashboard" });
    this.fetchData();
  }

  componentDidMount() {
    setTimeout(
      () => this.setState({ isRunning: !cookie.load("demoCompleted") }),
      1000
    );
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo() {
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("dashboard_info", !isInfoOpen);
  }

  callback(data) {
    if (data.type === "finished") cookie.save("demoCompleted", true);
    this.setState({
      selector: data.type === "tooltip:before" ? data.step.selector : "",
    });
  }

  fetchData() {
    this.setState({ isLoading: true });
    this.props.OnboardDucks.getTutorial().then(() => {
      const { tutorialDetail } = this.props;
      if (tutorialDetail && !tutorialDetail.tutorial_completed) {
        this.props.EmployeeDucks.getAllEmployees({ paginate: false });
      }
    });
    Promise.all([
      this.props.ReportDucks.getReports(),
      this.props.EmployeeDucks.getStats(),
      this.props.SystemSettingDucks.getSystemSettings(),
      this.props.TimecardDucks.getUserFilters({
        preference_type: "user_preference_dashboard",
      }),
    ])
      .then(() => {
        const { newDashboardValue } = this.props;
        this.setState({
          isLoading: false,
          newDashboard: newDashboardValue?.data?.newDashboard,
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { isLoading, isInfoOpen, newDashboard } = this.state;
    const { infoList, tutorialDetail, profile, userExtraData } = this.props;
    return (
      <section>
        {tutorialDetail &&
        Object.keys(tutorialDetail).length &&
        tutorialDetail.tutorial_completed ? (
          <TwoColumnLayout
            navInfo={NAV.DashboardPage}
            infoList={infoList}
            toggleInfo={this.toggleInfo}
            isInfoOpen={isInfoOpen}
            isdashboard="dashboard"
            newDashboard={newDashboard}
          >
            {isLoading ? (
              <SectionLoaderAtom active />
            ) : (
              <Row className="mx-1 ml-3 mr-4 pt-5 align-items-center">
                <Col sm="6" className="mt-2 pl-0">
                  <div className="black-text parent-heading mb-1">
                    Welcome to your dashboard
                  </div>
                </Col>
                <Col
                  sm="6"
                  className="mt-2 mb-2 text-right d-flex justify-content-end align-items-center"
                >
                  {userExtraData?.company_permissions?.new_dashboard === true && (
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        "margin-right": "10px",
                      }}
                    >
                      <span>Old Dashboard</span>
                      <Switch
                        onChange={(checked) => {
                          this.updateState("newDashboard", checked);
                          const preferenceObj = {
                            preference_type: "user_preference_dashboard",
                            data: {
                              newDashboard: checked,
                            },
                          };
                          this.props.TimecardDucks.updateUserFilters(
                            preferenceObj
                          );
                        }}
                        checked={this.state.newDashboard}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={18}
                        width={36}
                        onColor="#09819A"
                      />
                      <span>New Dashboard</span>
                    </div>
                  )}
                  {userExtraData?.company_permissions?.new_dashboard === false ? (
                    <RefreshIconAtom
                      className="cursor-pointer ml-2 refresh-style"
                      height="15px"
                      width="15px"
                      onClick={this.fetchData}
                      id="refresh"
                    />
                  ) : this.state.newDashboard === false ? (
                    <RefreshIconAtom
                      className="cursor-pointer ml-2 refresh-style"
                      height="15px"
                      width="15px"
                      onClick={this.fetchData}
                      id="refresh"
                    />
                  ) : null}
                </Col>
              </Row>
            )}
            <div>
              <hr className="hr-style" />
            </div>
            {userExtraData?.company_permissions?.new_dashboard === true &&
            newDashboard ? (
              <NewDashboard />
            ) : (
              <DashboardListSection isLoading={isLoading} />
            )}
          </TwoColumnLayout>
        ) : (
          <TwoColumnLayout
            navInfo={NAV.DashboardPage}
            isFinished
            isdashboard="dashboard"
          >
            <Row className="mx-1 ml-3 mr-4 pt-5">
              <Col sm="6" className="mt-2 pl-0">
                <div className="black-text parent-heading mb-1">
                  Welcome To Worksana, {profile.first_name}
                </div>
                <p className="sub-text-db">
                  Use the modules below for a quick tour of your new time
                  tracking solution.
                </p>
              </Col>
              <Col sm="6" className="mt-2">
                <RefreshIconAtom
                  className="cursor-pointer float-sm-right ml-4 refresh-style"
                  height="15px"
                  width="15px"
                  onClick={this.fetchData}
                  id="refresh"
                />
              </Col>
            </Row>
            <div>
              <hr className="hr-style" />
            </div>
            <DashboardOnboardListSection isLoading={isLoading} />
          </TwoColumnLayout>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  joyRidePerms: UserDucks.joyRidePerms(state),
  infoList: InfoDucks.infoList(state),
  profile: UserDucks.profile(state),
  tutorialDetail: OnboardDucks.tutorialDetail(state),
  userExtraData: UserDucks.userExtraData(state),
  newDashboardValue: TimecardDucks.userTimecardFilters(state),
});

const mapActionsToProps = (dispatch) => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
  SystemSettingDucks: bindActionCreators(SystemSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  OnboardDucks: bindActionCreators(OnboardDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

DashboardPage.propTypes = {
  EmployeeDucks: PropTypes.object.isRequired,
  ReportDucks: PropTypes.object.isRequired,
  SystemSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
};

DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(DashboardPage);