import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";

import * as RouteDucks from "ducks/routes";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import * as UserDucks from 'ducks/accounts/user';
import bindActionCreators from "redux/lib/bindActionCreators";

import { NAV } from "constants/forms";
import TwoColumnLayout from "layouts/TwoColumn";

import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";

import PaginationComponent from "components/common/Pagination";
import GenericConfirmationModal from "components/common/GenericDeleteConfirmationModal";

import FormsFilterForms from "forms/components/filterForms";
import SystemFormsListComponent from "forms/components/systemFormsList";
import SilderPreview from "forms/components/sliderPreview";
import moment from "moment";
import SSTCFLayout from "layouts/SSTCF";

class SystemFormsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      systemFormList: null,
      systemFormListCount: null,
      isLoading: false,
      preview: false,
      clickedTemplateData: [],
      statusFlag: false,
      formData: null,
      isSubmitting: false,
      assignEmpList: [],
    };
    this.formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(this.formsAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.initialState = this.initialState.bind(this);
    this.toggleStatusModal = this.toggleStatusModal.bind(this);
    this.handleStatusForm = this.handleStatusForm.bind(this);
    this.getEmployeeList = this.getEmployeeList.bind(this);
  }

  toggleStatusModal(item, flag) {
    this.setState({ statusFlag: flag, formData: item });
  }

  handleStatusForm() {
    const { formData } = this.state;
    this.setState({ isSubmitting: true });
    let obj = {
      id: formData.id,
      is_active: !formData.status,
    };
    this.usecase.toggleStatusForm(obj);
  }

  getEmployeeList(formId, index){
    const  { assignEmpList } = this.state;
    if(assignEmpList[`formEmpList${index}`] === undefined || assignEmpList[`formEmpList${index}`][0] === "Loading..."){
      this.setState({ 
        assignEmpList : {
        ...assignEmpList,
        [`formEmpList${index}`]: ["Loading..."],
        }
      });
      this.formsAPIGateway.getAssignedEmployeesList({ form_id: formId, paginate: false })
      .then(res => {
        const results = res.results.map(item => item.name)
        this.setState({assignEmpList : {
          ...assignEmpList,
          [`formEmpList${index}`]: results,
          }
        });
      }).catch(err => {
        this.setState({assignEmpList : {
          ...assignEmpList,
          [`formEmpList${index}`]: undefined,
          }
        });
      })
    }
  }

  initialState() {
    this.setState({ preview: false });
  }

  componentDidMount() {
    this.listenListUsecase();
    this.loadData();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  loadData() {
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);
    this.initialState();
    const pageSize = cookie.load("system_form_page_size") || 10;
    if (!("page_size" in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          page_size: pageSize,
          page: query.page || 1,
          is_active: true,
        }),
      });
    } else {
      this.fetchData(query);
    }
  }

  fetchData(params) {
    let obj = {
      ...params,
      form_type: "system",
      list_survey_type: [
        "delayed_meal",
        "missing_meal",
        "geofence_breach",
        "force_punch_out",
        "force_punch_out&delayed_meal",
        "force_punch_out&missing_meal",
      ],
    };
    this.initialState();
    this.usecase.getFormsList(obj);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  togglePreview(data, flag) {
    this.setState({ preview: flag, clickedTemplateData: data });
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_FORMS_LIST_SUCCESS":
          this.updateState("systemFormList", event.data.results);
          this.updateState("systemFormListCount", event.data.count);
          break;

        case "GET_FORMS_LIST_FAILURE":
          this.updateState("systemFormList", []);
          this.updateState("systemFormListCount", 0);
          toast.error("Something went wrong!");
          break;

        case "STATUS_CHANGED_SUCCESS":
          toast.success("Status Changed Successfully !!!");
          this.setState({ isSubmitting: false });
          this.toggleStatusModal();
          this.loadData();
          break;

        case "STATUS_CHANGED_FAILURE":
          this.setState({ isSubmitting: false });
          toast.error("Something went wrong !!!");
          break;

        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;

        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;

        case "ERROR_TOAST":
          toast.error("Something went wrong!");
          break;

        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  render() {
    const { dateFormat, location, history, userExtraData } = this.props;
    const {
      isLoading,
      preview,
      systemFormList,
      systemFormListCount,
      clickedTemplateData,
      statusFlag,
      isSubmitting,
      assignEmpList,
    } = this.state;

    const query = queryString.parse(location.search);
    const tabs = [
      { href: "/vendor/basic-setting", label: "Basic" },
      { href: "/vendor/system-setting", label: "System" },
      { href: "/vendor/notification-setting", label: "Notification" },
      { href: "/vendor/adjustment-types", label: "Adjustment Types" },
      { href: "/vendor/system-forms", label: "System Forms" },
    ];

    const condition = userExtraData?.company_permissions?.meal_adjustments_enabled;   

if (condition) {
  tabs.push({href: "/vendor/meal-adjustments", label: "Meal Adjustments"},);
}

    return (
      <div style={{ display: preview ? "flex" : null }}>
        <div style={{ width: preview ? "70%" : "100%" }}>
          <SSTCFLayout
            navInfo={NAV.SystemFormsPage}
            tabItems={tabs.filter((item) => item)}
            fetchData={this.loadData}
          >
            {/* <TwoColumnLayout
            navInfo={NAV.SystemFormsPage}
            fetchData={() => this.fetchData(query)}
          > */}
            <div
              style={{ background: "white" }}
              className="mt-2 ml-3 mr-4 pb-3 pt-3"
            >
              <FormsFilterForms
                dateFormat={dateFormat}
                location={location}
                formType="system"
              />
              <SystemFormsListComponent
                list={systemFormList}
                isLoading={isLoading}
                location={location}
                history={history}
                handlePreview={this.togglePreview}
                handleStatus={this.toggleStatusModal}
                assignEmpList={assignEmpList}
                getEmployeeList={this.getEmployeeList}
              />
            </div>
            {systemFormListCount && !isLoading ? (
              <PaginationComponent
                count={systemFormListCount}
                location={location}
                cookieKey="system_form_page_size"
                history={this.props.history}
              />
            ) : null}
            {/* </TwoColumnLayout> */}
          </SSTCFLayout>
        </div>
        {preview && (
          <SilderPreview
            togglePreview={this.togglePreview}
            show={preview}
            templateData={clickedTemplateData}
          />
        )}
        {statusFlag && (
          <GenericConfirmationModal
            handleConfirmation={this.handleStatusForm}
            toggle={this.toggleStatusModal}
            isSubmitting={isSubmitting}
            text={"Are you sure you want to change the status ?"}
            isOpen={statusFlag}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemFormsPage);
