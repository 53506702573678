const baseNav = [
    { href: '/dashboard', label: 'Dashboard' },
    { href: '/quality-assurance/list', label: 'Quality Assurance' },
  ];
  
  export const NAV = {
    QualityAssuranceListPage: {
      title: 'Quality Assurance',
      breadcrumb: [
        ...baseNav,
        { label: 'All' },
      ],
      description: 'Upload and Download Quality Assurance Documents',
    },
  };
  
 
  export default {NAV};
  