import React from "react";

import PropTypes from "prop-types";
import StorageGateway from "lib/storage-gateway";
import NavLink from "react-router-dom/NavLink";

import NavItem from "reactstrap/lib/NavItem";

import TreeView from "atoms/TreeView";

import routesMapping from "constants/routes";
import styles from "./styles.module.scss";
import CollapseNav from "./CollapseNav";
import { ReactComponent as EmployeesImage } from "./employees.svg";
import { ReactComponent as GeoFencesImage } from "./geofences.svg";
import { ReactComponent as KnowledgeBaseImage } from "./knowledge.svg";
import { server } from "helpers/config";
import { ReactComponent as DashboardImage } from "./overview.svg";
import { ReactComponent as TimecardsImage } from "./timecards.svg";
import { ReactComponent as JobImage } from "./job.svg";
import { ReactComponent as RecordsImage } from "./Records.svg";
import { ReactComponent as TrackingImage } from "./tracking.svg";
import { ReactComponent as CustomerImage } from "./Customer.svg";
import { ReactComponent as GeoFenceImage } from "./Geofence.svg";
import { ReactComponent as JobDashboardImage } from "./Job Dashboard.svg";
import { ReactComponent as ReportImage } from "./reports.svg";
import { ReactComponent as TaskImage } from "./Task.svg";
import { ReactComponent as PremiumFeature } from "./PremiumFeature.svg";
import { ReactComponent as ShiftIcon } from "./Shift-Management.svg";
import { ReactComponent as PtoImage } from "./Time_Off_Request.svg";
import SetupIcon from "./setupchecklist-grey.png";
import { ReactComponent as SurveyIcon } from "./Survey.svg";
import { ReactComponent as SurveyDashboard } from "./SurveyDashboard.svg";
import { ReactComponent as SurveyTemplate } from "./SurveyTemplate.svg";
import { ReactComponent as ManageJob } from "./ManageJob.svg";
import { ReactComponent as ApplicantsImage } from "./Applicant_White.svg";

// import { ReactComponent as NotificationFeature } from "./notification-feature.svg";
// import { ReactComponent as DocumentCompletionReport } from "./document-completion-report.svg";
// import { ReactComponent as NotificationList } from "./notification-board.svg";
import { ReactComponent as BillsIcon } from "./payment.svg";
import { ReactComponent as BroadcastIcon } from "./Broadcast.svg";
import { ReactComponent as CommunicationCenter } from "./CommunicationCenter.svg";
import { ReactComponent as DocumentTracking } from "./DocumentTracking2.svg";
import { ReactComponent as DocumentSetUp } from "./DocumentSetUp.svg";
import { ReactComponent as FieldForms } from "./FieldForms2.svg";
import { ReactComponent as LearningCenter } from "./LearningCenter.svg";
import { ReactComponent as Tracking } from "./Tracker.svg";
import { ReactComponent as LearningenterSetup } from "./Setup.svg";
import { ReactComponent as Broadcasting } from "./Broadcasting.svg";

const NavComponent = (props, context) => {
  const { nav, sidemenuCollapsed, userExtraData } = props;
  const {
    router: {
      route: { location },
    },
  } = context;

  const images = {
    activity: (
      <TrackingImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "employee.list": (
      <EmployeesImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    dashboard: (
      <DashboardImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    dashboard_admin: (
      <DashboardImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    report: (
      <RecordsImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    vendor: (
      <EmployeesImage
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    geofence: (
      <GeoFencesImage
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    job: (
      <JobImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    knowledge_base: (
      <KnowledgeBaseImage
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    quality_assurance: (
      <KnowledgeBaseImage
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    smart_connector: (
      <KnowledgeBaseImage
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    setup_checklist: (
      <img src={SetupIcon} alt="icon" className={styles.nodeIconCheck} />
    ),
    "timecards.timecard": (
      <TimecardsImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "job.list": (
      <JobDashboardImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "customer.list": (
      <CustomerImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "task.list": (
      <TaskImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "geofences.list": (
      <GeoFenceImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "reports.list": (
      <ReportImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    bill: (
      <BillsIcon
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "surveys.list": (
      <SurveyIcon
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "premium_features.list": (
      <PremiumFeature
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "jobs.new.list": (
      <ManageJob
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    communication: (
      <CommunicationCenter
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "communication.list": (
      <BroadcastIcon
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "document.completion.report": (
      <DocumentTracking
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "forms.field": (
      <FieldForms
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "documents.setup": (
      <DocumentSetUp
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    learning: (
      <LearningCenter
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "learning.communication.list": (
      <Broadcasting
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "learning.completion.report": (
      <Tracking
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "learning.setup": (
      <LearningenterSetup
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "applicant.list": (
      <ApplicantsImage
        alt="icon"
        className={
          !sidemenuCollapsed ? styles.nodeIconSvg : styles.nodeIconSvgCollapsed
        }
      />
    ),

    "scheduling.dashboard":(
      <ShiftIcon
        alt="icon"
        className={
          !sidemenuCollapsed
            ? styles.nodeIconSvg
            : styles.nodeIconSvgCollapsed
        }
      />
    ),
    "pto.list":(
      <PtoImage
        alt="icon"
        className={
          !sidemenuCollapsed
            ? styles.nodeIconSvg
            : styles.nodeIconSvgCollapsed
        }
      />
    ),
  };
  const isSelected = (name) => location.pathname === routesMapping[name];
  const isCollapsed = (level) => {
    const hasSelected = level.children.filter((item) => isSelected(item.name))
      .length;
    if (hasSelected > 0) return false;
    return true;
  };
  const isChildSelected = (parent) => {
    if (parent.children.length) {
      const child = parent.children.filter(
        (item) => location.pathname.includes(routesMapping[item.name]) === true
      );
      if (child.length) return true;
    }
    return false;
  };
  const isSurveyCollapsed = () => {
    if (isSelected("surveys.dashboard") || isSelected("surveys.list")) {
      return false;
    }
    return true;
  };

  const isFormsCollapsed = () => {
    if (
      isSelected("forms.fieldforms") ||
      isSelected("forms.systemforms") ||
      isSelected("forms.assessmentforms")
    ) {
      return false;
    }
    return true;
  };

  const isPremiumCollapsed = () => {
    if (isSelected("premium_features.list") || isSelected("pto.list")) {
      return false;
    }
    return true;
  };

  const companyId = StorageGateway.get("company_id");
  return (
    <div>
      {!sidemenuCollapsed ? (
        <section
          className={
            companyId && props.showFlap
              ? "side-nav-common__list side-nav-flap-list"
              : (companyId && !props.showFlap) || (!companyId && props.showFlap)
              ? "side-nav-common__list side-nav-business-list"
              : "side-nav-common__list"
          }
        >
          {nav.map((l1) =>
            l1.children && l1.children.length > 0 ? (
              <TreeView
                key={l1.name}
                treeId={l1.name}
                nodeLabel={l1.label}
                nodeIcon={images[l1.name]}
                defaultCollapsed={isCollapsed(l1)}
              >
                {l1.children.map((l2) =>
                  l2.children && l2.children.length > 0 ? (
                    <TreeView
                      key={l2.name}
                      treeId={l2.name}
                      nodeLabel={l2.label}
                      defaultCollapsed={isCollapsed(l2)}
                    >
                      {l2.children.map((l3) => (
                        <NavItem
                          key={l3.name}
                          id={l3.name}
                          className={
                            isSelected(l3.name) ? styles["selected-link"] : ""
                          }
                        >
                          <NavLink
                            to={routesMapping[l3.name]}
                            activeClassName={styles["selected-tab"]}
                          >
                            {l3.label}
                          </NavLink>
                        </NavItem>
                      ))}
                    </TreeView>
                  ) : (
                    <NavItem
                      key={l2.name}
                      id={l2.name}
                      className={
                        isSelected(l2.name) ? styles["selected-link"] : ""
                      }
                    >
                      <NavLink
                        to={routesMapping[l2.name]}
                        className={
                          isSelected(l2.name) ? styles["selected-tab"] : ""
                        }
                      >
                        {images[l2.name]}
                        {l2.label}
                      </NavLink>
                    </NavItem>
                  )
                )}
              </TreeView>
            ) : (
              <NavItem
                key={l1.name}
                id={l1.name}
                className={isSelected(l1.name) ? styles["selected-link"] : ""}
              >
                <NavLink
                  to={routesMapping[l1.name]}
                  activeClassName={
                    isSelected(l1.name) ? styles["selected-tab"] : ""
                  }
                >
                  {images[l1.name]}
                  {l1.label}
                </NavLink>
              </NavItem>
            )
          )}
          {/* {userExtraData.company_permissions.work_shift &&
            !userExtraData.is_business_admin && (
              <a href={schedulingUrl} className={styles["a-tag"]} target="_blank">
                <img
                  src={ShiftIcon}
                  alt="icon"
                  className={styles.nodeIconSvg}
                />
                Scheduling
              </a>
            )} */}
          {userExtraData.company_permissions.feedback && (
            <TreeView
              key={"surveys.list"}
              treeId={"surveys.list"}
              nodeLabel={"Surveys"}
              nodeIcon={images["surveys.list"]}
              defaultCollapsed={isSurveyCollapsed()}
            >
              <NavItem
                key={"surveys.dashboard"}
                id={"surveys.dashboard"}
                className={
                  isSelected("surveys.dashboard") ? styles["selected-link"] : ""
                }
              >
                <NavLink
                  to={routesMapping["surveys.dashboard"]}
                  activeClassName={
                    isSelected("surveys.dashboard")
                      ? styles["selected-tab"]
                      : ""
                  }
                >
                  <SurveyDashboard
                    alt="icon"
                    className={
                      !sidemenuCollapsed
                        ? styles.nodeIconSvg
                        : styles.nodeIconSvgCollapsed
                    }
                  />
                  Survey Dashboard
                </NavLink>
              </NavItem>
              <NavItem
                key={"surveys.list"}
                id={"surveys.list"}
                className={
                  isSelected("surveys.list") ? styles["selected-link"] : ""
                }
              >
                <NavLink
                  to={routesMapping["surveys.list"]}
                  activeClassName={
                    isSelected("surveys.list") ? styles["selected-tab"] : ""
                  }
                >
                  <SurveyTemplate
                    alt="icon"
                    className={
                      !sidemenuCollapsed
                        ? styles.nodeIconSvg
                        : styles.nodeIconSvgCollapsed
                    }
                  />
                  Survey Template
                </NavLink>
              </NavItem>
            </TreeView>
          )}
           {/* {(userExtraData.company_permissions.work_shift ||
             userExtraData.company_permissions.paid_time_off) &&
             !userExtraData.is_business_admin && (
               <TreeView
                 key={"premium_features.list"}
                 treeId={"premium_features.list"}
                 nodeLabel={"Premium Features"}
                 nodeIcon={images["premium_features.list"]}
                 defaultCollapsed={isPremiumCollapsed()}
               >
                 {userExtraData.company_permissions.work_shift && (
                   <NavItem
                     key={"scheduling.dashboard"}
                     id={"scheduling.dashboard"}
                     className={
                       isSelected("premium_features.list")
                         ? styles["selected-link"]
                         : ""
                     }
                   >
                     <NavLink
                       to={routesMapping["scheduling.dashboard"]}
                       activeClassName={
                         isSelected("premium_features.list")
                           ? styles["selected-tab"]
                           : ""
                       }
                     >
                       <ShiftIcon
                         alt="icon"
                         className={
                           !sidemenuCollapsed
                             ? styles.nodeIconSvg
                             : styles.nodeIconSvgCollapsed
                         }
                       />
                       Scheduling
                     </NavLink>
                   </NavItem>
                 )}
                 {userExtraData.company_permissions.paid_time_off &&
                   (userExtraData.company_permissions.company_owner ||
                     userExtraData.pto_supervisor) && (
                     <NavItem
                       key={"pto.list"}
                       id={"pto.list"}
                       className={
                         isSelected("pto.list") ? styles["selected-link"] : ""
                       }
                     >
                       <NavLink
                         to={routesMapping["pto.list"]}
                         activeClassName={
                           isSelected("pto.list") ? styles["selected-tab"] : ""
                         }
                       >
                         <PtoImage
                           alt="icon"
                           className={
                             !sidemenuCollapsed
                               ? styles.nodeIconSvg
                               : styles.nodeIconSvgCollapsed
                           }
                         />
                         Time Off Request
                       </NavLink>
                     </NavItem>
                   )}
               </TreeView>
	             )} */}
        </section>
      ) : (
        <section className="side-nav__list-collapse text-center mt-4 pt-3">
          <CollapseNav
            nav={nav}
            images={images}
            isSelected={isSelected}
            isChildSelected={isChildSelected}
            userExtraData={userExtraData}
          />
        </section>
      )}
    </div>
  );
};

NavComponent.defaultProps = {
  nav: [
    {
      id: 1,
      name: "dashboard",
      label: "Overview",
      children: [],
    },
  ],
};

NavComponent.propTypes = {
  nav: PropTypes.array.isRequired,
};

NavComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default NavComponent;
