import React, { useParams } from "react";

import StorageGateway from "lib/storage-gateway";
import { toast } from "react-toastify";
//import { useParams } from "react-router-dom";

/**
 * PayrollSettingListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 * Fetch Needs:
 *    - getPayrollSetting
 */
let oAuthToken = "";
let company_id = "";
class EmployeeTimeOffTable extends React.Component {
  constructor(props) {
    super(props);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
  }

  /**
 * Event listener function to handle various slider and toast actions based on the message received from the event.
 *
 * @param {Object} event - The event object containing data for the actions.
 * @property {string} event.data - The data sent along with the event, typically a JSON string.
 * @property {string} event.data.message - The type of action message that triggers specific actions.
 * @property {Object} event.data.content - Additional content to be used in some actions.
 */

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "OPEN_ADD_TIME_OFF_SLIDER") {
      this.props.closeSlider(true);
    } else if (message === "CLOSE_ADD_TIME_OFF_SLIDER") {
      this.props.closeSlider(false);
    } else if (message === "OPEN_TIME_OFF_REQUEST_SLIDER") {
      this.props.toggleTimeOffRequestSlider({
        status: true,
        policyId: data?.content?.policyId,
      });
    } else if (message === "CLOSE_TIME_OFF_REQUEST_SLIDER") {
      this.props.toggleTimeOffRequestSlider({ status: false });
    } else if (message === "OPEN_VIEW_TIME_OFF_POLICY_PAGE") {
      this.props.toggleViewTimeOffPoliciesSlider(true);
    } else if (message === "SUCCESS_PTO_TOAST") {
      toast.success(data?.content?.toastMessage);
      this.props.postMessageToChild("REFRESH_EMPLOYEE_TIMEOFF");
      this.props.toggleTimeOffRequestSlider({ status: false });
      this.props.updateState('ptoWarningModal' , data?.content?.showWarning);
      this.props.updateState('ptoWarningMessage' , data?.content?.warning);
      this.props.updateState('user_Id' , data?.content?.userId);
      //  this.fetchData()
    } else if (message === "FAILURE_PTO_TOAST") {
      toast.error(data?.content?.toastMessage);
      
    } else if(message === "OPEN_ACCURAL_HISTORY_SLIDER") {
      this.props.toggleHistorySlider({
        status: true,
        policy_id: data?.content?.policyDetails.id,
        policy_name: data?.content?.policyDetails.name,
        policy_type: data?.content?.policyDetails?.type
      });
    } else if (message === "SUCCESS_TOAST_BALANCE") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "FAILURE_TOAST_BALANCE") {
      toast.error(data?.content?.toastMessage);
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  componentWillMount() {
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
  }

  render() {
    return (
      <div
        style={{
          height: "calc((90vh - 102px) - 65px)",
          marginRight: "-15px",
         // marginTop: "-1rem",
        }}
      >
          <iframe
            id="scheduling"
            title="scheduling-iframe"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-policy/employee-edit?oAuthToken=${oAuthToken}&company_id=${company_id}&employee_id=${this.props.employeeId}&employee_name=${this.props.employeeName}`}
          />
      </div>
    );
  }
}

export default EmployeeTimeOffTable;
