import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import closeimage from "accounts/components/CloseCross.png";
import "pages/qualityAssurance/css/previewModal.css";

const PreviewModal = ({ isOpen, toggle, url, getPreviewContent,extension }) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={(extension === "csv" || extension === "apk") ? "modal-container-css" : "modal-container-img-css"}
      modalClassName={(extension === "csv" || extension === "apk") ? "modal-mains-css" : "modal-mains-img-css"}
      className={"modal-box-css"}
    >
      <ModalHeader>
        Preview Component
        <img
          src={closeimage}
          className="close_image"
          height="14px"
          width="14px"
          alt="Close"
          onClick={toggle}
        />
      </ModalHeader>
      <ModalBody className="pt-0">
        <div style={{ justifyContent: "center", display: "flex" }}>
          {getPreviewContent(url)}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PreviewModal;
