import React, { useState } from 'react';
import 'pages/qualityAssurance/css/ToggleButtonComponent.css'; // Import the CSS file

const ToggleButtonComponent = ({isActive,toggleSwitch,tableLoader}) => {
  
  return (
    <div className={tableLoader?"toggle-container-disable":"toggle-container"}>
      <div className={tableLoader?"toggle-button-disable":"toggle-button"} onClick={!tableLoader && toggleSwitch}>
        <span className={tableLoader?`toggle-option-disable ${!isActive ? 'active' : ''}`:`toggle-option ${!isActive ? 'active' : ''}`} id="false-option">
          Web
        </span>
        <span className={tableLoader?`toggle-option-disable ${isActive ? 'active' : ''}`:`toggle-option ${isActive ? 'active' : ''}`} id="true-option">
          Mobile
        </span>
      </div>
    </div>
  );
};

export default ToggleButtonComponent;
