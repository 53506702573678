import React, { useEffect, useState } from "react";
import StorageGateway from "lib/storage-gateway";
import { toast } from "react-toastify";

const SmartConnectList = (props) => {
  const [oAuthToken, setOAuthToken] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    // Fetch tokens
    const token = StorageGateway.get("graniteToken");
    const company_id = StorageGateway.get("company_id");
    const user_id = StorageGateway.get("userId");

    setOAuthToken(token);
    setCompanyId(company_id);
    setUserId(user_id);
  }, []);

  useEffect(() => {
    console.log(userId); // To check if userId is correctly fetched
  }, [userId]);

  return (
    <div
      style={{
        height: "calc((90vh - 102px) - 65px)",
        marginRight: "-15px",
        // marginTop: "-1rem",
      }}
    >
      <iframe
        id="nre"
        title="nre-iframe"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ width: "100%", height: "100%" }}
        src={`${process.env.REACT_APP_WMAPPER_BASE_URL}/smart-connect/?access_token=${oAuthToken}&company_id=${companyId}&user_id=${userId}`}
      />
    </div>
  );
};

export default SmartConnectList;
